import React, { FC } from 'react';
import styled from 'styled-components';
import { ExternalLink } from './ExternalLink';
import { Link } from './Link';
import { Price } from './Price';
import { ETHERSCAN_TRANSACTION_URL } from '../config';
import { Sale } from '../types';
import { repeat, shortAddress } from '../utils';
import BN from 'bignumber.js';

const Row = styled.div`
  display: grid;
  gap: ${t => t.theme.space.s};
  align-items: center;
  padding: ${t => t.theme.space.s};
  background: ${t => t.theme.color.bg.a};
  &:nth-child(2n + 1) {
    background: ${t => t.theme.color.bg.b};
  }
  &:first-child {
    background: ${t => t.theme.color.bg.c};
    padding-top: 0;
  }
`;
const Item = styled(Link)`
  display: flex;
  gap: 0.5em;
  align-items: center;
  color: ${t => t.theme.color.text.body};
`;

export type ActivityTableProps = {
  collection: string;
  activity: Sale[];
  className?: string;
  showItem?: boolean;
};

export const ActivityTable: FC<ActivityTableProps> = ({
  collection,
  activity,
  className,
  showItem = true,
}) => {
  const gridTemplateColumns = repeat(showItem ? 5 : 4, () => '1fr').join(' ');
  return (
    <div className={className}>
      <Row style={{ gridTemplateColumns }}>
        {showItem && <div>Item</div>}
        <div>Price</div>
        <div>From</div>
        <div>To</div>
        <div>Time</div>
      </Row>
      {activity.map(
        (
          { fromAddress, toAddress, price, time, txHash, tokenId, image },
          i,
        ) => (
          <Row key={i} style={{ gridTemplateColumns }}>
            {showItem && (
              <Item href={`/collection/${collection}/${tokenId}`}>
                <img src={image.replace('ipfs.infura.io', 'heroes.infura-ipfs.io')} height={30} width={30} alt="" />
                {collection} #{tokenId}
              </Item>
            )}
            <Price price={new BN(price).div(10 ** 18).toString()} />
            <div>
              <Link href={`/wallet/${fromAddress}`}>
                {shortAddress(fromAddress)}
              </Link>
            </div>
            <div>
              <Link href={`/wallet/${toAddress}`}>
                {shortAddress(toAddress)}
              </Link>
            </div>
            <div>
              <ExternalLink href={`${ETHERSCAN_TRANSACTION_URL}${txHash}`}>
                {time}
              </ExternalLink>
            </div>
          </Row>
        ),
      )}
    </div>
  );
};
