import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import getListings from '../../api/getListings';
import getListingsMetadata from '../../api/getListingsMetadata';
import {
  HandlerProps,
  Layout,
  PaginationControls,
  Row,
  Select,
  Stats,
  TokenGrid,
  TextButton,
} from '../../components';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { ROOT_COLLECTION } from '../../config';
import useStore from '../../dataStore/Store';
import { ListingsSort } from '../../types';
import { parsePage, useAsyncData } from '../../utils';
import { Filters } from './Filters';
import BN from 'bignumber.js';

const LeftColumn = styled.div`
  width: ${t => t.theme.layout.leftColumnWidth};
  background: ${t => t.theme.color.bg.b};
  @media (max-width: ${t => t.theme.layout.minWidth}) {
    width: 100%;
  }
`;

const RightColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: ${t => t.theme.s('l l xxl')};
`;

const FloorStats = styled(Stats)`
  padding: ${t => t.theme.space.l};
`;

const FiltersToggle = styled.div`
  display: none;
  @media (max-width: ${t => t.theme.layout.minWidth}) {
    display: flex;
  }
  padding: ${t => t.theme.space.l};
  background: ${t => t.theme.color.bg.b};
  border-bottom: solid 1px ${t => t.theme.color.border.a};
  justify-content: space-between;
`;

const ResponsiveFilters = styled.div<{ shown: boolean }>`
  ${t =>
    t.shown
      ? ''
      : `@media (max-width: ${t.theme.layout.minWidth}) { display: none }`}
`;

const RESULTS_PER_PAGE = 40;

export const ListingsPage: FC<HandlerProps> = ({
  path,
  pathParams: { collection = ROOT_COLLECTION },
  searchParams: { page: pageString },
}) => {
  const [filtersToggleShown, setFiltersToggleShown] = useState(false);
  const page = parsePage(pageString);
  const [state, setState] = useState<{
    traits: Set<string>;
    stats: Map<string, [number, number]>;
    sort: ListingsSort;
  }>({ traits: new Set(), stats: new Map(), sort: 'price-lth' });
  const { traits, stats, sort } = state;
  const [, , floorData] = useAsyncData(() => getListingsMetadata());
  const [, , listingsData] = useAsyncData(
    () =>
      getListings({
        collection,
        page,
        sort,
        resultsPerPage: RESULTS_PER_PAGE,
        traits: Array.from(traits.values()),
        stats: Array.from(stats.entries()).map(([id, [min, max]]) => ({
          id,
          min,
          max,
        })),
      }),
    [page, traits, stats, sort],
  );
  const store = useStore();
  useEffect(() => {
    store.getCategories();
  }, []);

  return (
    <Layout fullWidth>
      <Row align="stretch" responsive flex="1">
        <LeftColumn>
          <FiltersToggle
            onClick={() => setFiltersToggleShown(!filtersToggleShown)}
          >
            Filter{' '}
            <TextButton
              onClick={() => setFiltersToggleShown(!filtersToggleShown)}
            >
              {filtersToggleShown ? '-' : '+'}
            </TextButton>
          </FiltersToggle>
          <ResponsiveFilters shown={filtersToggleShown}>
            {floorData && (
              <FloorStats
                stats={[
                  {
                    name: 'Floor price',
                    price: new BN(floorData.price ?? '0')
                      .div(10 ** 18)
                      .toString(),
                    ethPrice: floorData.ethPrice,
                    numHearts: 3,
                  },
                  {
                    name: 'Total listings',
                    text: floorData.totalListings
                      ? `${floorData.totalListings}`
                      : '...',
                  },
                ]}
              />
            )}
            {store.categories && (
              <Filters
                categories={store.categories}
                traits={traits}
                stats={stats}
                updateTrait={(id, on) => {
                  const next = new Set(traits);
                  if (on) next.add(id);
                  else next.delete(id);
                  setState({ ...state, traits: next });
                }}
                updateStat={(id, val) => {
                  const next = new Map(stats);
                  if (val === null) next.delete(id);
                  else next.set(id, val);
                  setState({ ...state, stats: next });
                }}
                clearFilters={() =>
                  setState({ traits: new Set(), stats: new Map(), sort })
                }
              />
            )}
          </ResponsiveFilters>
        </LeftColumn>
        <RightColumn>
          <Row justify="space-between" align="center" padding="0 s m">
            <div>
              {listingsData?.totalResults &&
                `${listingsData.totalResults} items`}
            </div>
            <Select
              name="sort"
              value={sort}
              disabled={!listingsData}
              onChange={e => {
                console.log('e', e.target.value);
                setState({ ...state, sort: e.target.value as ListingsSort });
              }}
              options={[
                ['price-lth', 'Price low to high'],
                ['price-htl', 'Price high to low'],
                ['newest', 'Newest to oldest'],
                ['oldest', 'Oldest to newest'],
              ]}
            />
          </Row>
          {listingsData ? (
            <>
              <TokenGrid tokens={listingsData.results} />
              <PaginationControls
                page={page}
                resultsPerPage={RESULTS_PER_PAGE}
                totalResults={listingsData.totalResults}
                basePath={path}
              />
            </>
          ) : (
            <LoadingIndicator />
          )}
        </RightColumn>
      </Row>
    </Layout>
  );
};
