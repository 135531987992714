import React, { FC, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from './Link';
import { Price } from './Price';
import { shortAddress } from '../utils';
import useStore from '../dataStore/Store';
import {
  DISCORD_LINK,
  EXCHANGE_LINK,
  ROOT_COLLECTION,
  TWITTER_LINK,
} from '../config';
import { Button, Col, ExternalLink, HR, TextButton } from '.';
import { web3Util } from '../utils/web3Util';
import BN from 'bignumber.js';
import ConnectWallet from './ConnectWallet';
import { MAX_WIDTH } from '../style';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  min-height: 100vh;
`;

const Header = styled.header`
  background: ${t => t.theme.color.bg.a};
  border-bottom: solid 1px ${t => t.theme.color.border.a};
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HeaderLeft = styled.h1`
  width: calc(${t => `${t.theme.layout.leftColumnWidth} - ${t.theme.space.l}`});
  padding: ${t => t.theme.s('0 l')};
  margin: 0;
`;

const HeaderRight = styled.div`
  display: none;
  flex: 1;
  justify-content: flex-end;
  padding: ${t => t.theme.s('0 l')};
  @media (max-width: 80rem) {
    display: flex;
  }
`;

const Nav = styled.nav`
  flex: 1;
  display: flex;
  padding-left: calc(2 * ${t => t.theme.space.l});
  @media (max-width: 80rem) {
    display: none;
  }
`;

const NavItem = styled(Link)`
  padding: ${t => t.theme.s('0 l 0 0')};
  white-space: nowrap;
`;

const ExternalNavItem = styled(ExternalLink)`
  padding: ${t => t.theme.s('0 l 0 0')};
  white-space: nowrap;
`;

const Account = styled.div`
  font-size: ${t => t.theme.fontSize.s};
  display: flex;
  gap: 1rem;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: ${t => t.theme.s('0 l')};
  @media (max-width: 80rem) {
    display: none;
  }
`;

const BalanceWrap = styled.div`
  display: flex;

  flex-direction: column;
  gap: 0.5em;
`;

const Balance = styled(Price)`
  font-family: ${t => t.theme.font.display};
  font-size: ${t => t.theme.fontSize.m};
`;

const Main = styled.main<{ fullWidth?: boolean }>`
  width: 100%;
  max-width: ${t => (t.fullWidth ? 'none' : t.theme.layout.mainContentWidth)};
  margin: ${t => (t.fullWidth ? 0 : '0 auto')};
  padding: ${t => (t.fullWidth ? 0 : t.theme.space.l)};
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const MobileMenu = styled.div<{ open: boolean }>`
  display: none;
  max-width: ${t => t.theme.layout.mainContentWidth};
  padding: ${t => t.theme.space.l};
  ${t => (t.open ? '@media (max-width: 80rem) { display: block; nav }' : '')};
`;

const animation = keyframes`
  from {
    background-position: -300vw;
  }
  to {
    background-position: 300vw;
  }
`;

const Announcement = styled.div`
  background: rgb(63, 94, 251);
  background: linear-gradient(
    142deg,
    rgba(63, 94, 251, 1) 0%,
    rgba(252, 70, 107, 1) 50%,
    rgba(74, 93, 242, 1) 100%
  );
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  animation: ${animation} 30s infinite alternate-reverse;
  a {
    color: white;
    text-decoration: underline;
  }
`;

export const DungeonAnnouncement = styled(Announcement)`
  backgrond: none;
  animation: none;
  overflow: hidden;
  position: sticky;
  top: 0;
  z-index: 100;
  border-bottom: 3px solid rgba(223, 28, 28, 1);
  & div {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.2) 90%);
    & a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }
  & img {
    z-index: 1;
    image-rendering: pixelated;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
  }
`;

const HeaderInner = styled.div`
  width: 100%;
  max-width: ${MAX_WIDTH};
  display: flex;
  align-items: center;
`;

export type LayoutProps = {
  fullWidth?: boolean;
};

export const Layout: FC<LayoutProps> = ({ children, fullWidth }) => {
  let { viewerAddress, updateViewerAddress, balance } = useStore(
    ({ viewerAddress, updateViewerAddress, balance }: any) => ({
      viewerAddress,
      updateViewerAddress,
      balance,
    }),
  );
  const [menuOpen, setMenuOpen] = useState(false);

  if (balance) {
    balance = new BN(balance).toFixed(4);
  }

  useEffect(() => {
    const update = ([account]: [string]) => {
      updateViewerAddress(account);
    };
    web3Util.on('accountsUpdated', update);
    return () => {
      web3Util.off('accountsUpdated', update);
    };
  }, [updateViewerAddress]);
  console.log(viewerAddress);
  const nav = (
    <>
      {/* <NavItem href={`/listings`}>Listings</NavItem> */}
      {/* <NavItem href={`/collection/${ROOT_COLLECTION}/activity`}>
        Activity
      </NavItem> */}
      <ExternalNavItem href={EXCHANGE_LINK}>Buy Hearts</ExternalNavItem>
      <ExternalNavItem href={DISCORD_LINK}>Discord</ExternalNavItem>
      <ExternalNavItem href="https://opensea.io/collection/the-heroes-nft-collection">
        OpenSea
      </ExternalNavItem>
      <ExternalNavItem href={TWITTER_LINK}>Twitter</ExternalNavItem>
      <NavItem href="/staking">Staking</NavItem>
      {viewerAddress && (
        <NavItem href={`/wallet/${viewerAddress}`}>Your Heroes</NavItem>
      )}
    </>
  );
  const account = (
    <ConnectWallet>
      {viewerAddress && balance !== null && (
        <BalanceWrap>
          <Balance price={balance as string} />
          {shortAddress(viewerAddress)}
        </BalanceWrap>
      )}
    </ConnectWallet>
  );

  const play = (
    <a href="https://alpha.heroes.fun" target="_blank" rel="noreferrer">
      <Button>Play</Button>
    </a>
  );

  return (
    <Container>
      <DungeonAnnouncement>
        <div>
          <Link href="/dungeons">Dungeons Available Now!</Link>
        </div>
        <img src="/embers-glow.png" />
      </DungeonAnnouncement>
      <Header>
        <HeaderInner>
          <HeaderLeft>
            <Link href="/">
              <img src="/logo.png" alt="HEROES" height={26} />
            </Link>
          </HeaderLeft>
          <Nav>{nav}</Nav>
          <Account>
            {play}
            {account}
          </Account>
          <HeaderRight>
            <TextButton onClick={() => setMenuOpen(!menuOpen)}>menu</TextButton>
          </HeaderRight>
        </HeaderInner>
      </Header>
      <MobileMenu open={menuOpen}>
        <Col padding="m">{account}</Col>
        <HR />
        <Col padding="m" gap="m">
          {nav}
        </Col>
      </MobileMenu>
      <Main fullWidth={fullWidth}>{children}</Main>
    </Container>
  );
};
