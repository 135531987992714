import { web3Util } from '../utils/web3Util';
import BN from 'bignumber.js';
import { tx } from './tx';

export const APPROVAL_AMOUNT = new BN(9999999999999).times(10 ** 18).toFixed();

export async function approveHeartsStaking() {
  const contract = web3Util.getContract('hearts');
  return tx(
    contract.methods.approve(
      process.env.REACT_APP_HEART_STAKING,
      APPROVAL_AMOUNT,
    ),
  );
}

export async function approveLPStaking() {
  const contract = web3Util.getContract('lp');
  return tx(
    contract.methods.approve(process.env.REACT_APP_LP_STAKING, APPROVAL_AMOUNT),
  );
}
