import React, { FC } from 'react';
import { A } from './A';

export type ExternalLinkProps = {
  href: string;
  className?: string;
  disabled?: boolean;
};

export const ExternalLink: FC<ExternalLinkProps> = ({
  href,
  children,
  className,
  disabled,
}) => {
  return (
    <A
      href={disabled ? undefined : href}
      className={className}
      disabled={disabled}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </A>
  );
};
