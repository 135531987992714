import React, { FC } from 'react';
import styled from 'styled-components';
import { Price } from '.';

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${t => t.theme.space.m};
`;

const Stat = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${t => t.theme.space.xs};
`;

const StatLabel = styled.div`
  font-family: ${t => t.theme.font.display};
  font-size: ${t => t.theme.fontSize.m};
`;

export type StatsProps = {
  stats: {
    name: string;
    price?: string;
    ethPrice?: string;
    numHearts?: number;
    text?: string;
  }[];
  className?: string;
};

export const Stats: FC<StatsProps> = ({ stats, className }) => {
  return (
    <Main className={className}>
      {stats.map((stat, i) => (
        <Stat key={i}>
          <StatLabel>{stat.name}</StatLabel>
          {stat.price !== undefined ? (
            <Price
              numHearts={stat.numHearts}
              price={stat.price}
              ethPrice={stat.ethPrice}
            />
          ) : (
            <span>{stat.text}</span>
          )}
        </Stat>
      ))}
    </Main>
  );
};
