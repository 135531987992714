import React, { FC } from 'react';
import styled from 'styled-components';
import { CollapsableSection } from '../../components';
import { TraitCategory } from '../../types';

export const Fieldset = styled.fieldset`
  display: flex;
  flex-direction: column;
  border: none;
  padding: ${t => t.theme.s('0 l l')};
  gap: 21px;
`;
export const Label = styled.label``;
export const Input = styled.input`
  margin: 0;
  border: solid 1px ${t => t.theme.color.border.b};
  border-radius: ${t => t.theme.borderRadius.xs};
  appearance: none;
  width: 11px;
  height: 11px;
  position: relative;
  &:checked:after {
    content: '';
    position: absolute;
    width: 5px;
    height: 5px;
    left: 2px;
    top: 2px;
    border-radius: ${t => t.theme.borderRadius.xs};
    background: ${t => t.theme.color.text.link};
  }
`;

const TextWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

export type TraitControlProps = {
  category: TraitCategory;
  traits: Set<string>;
  updateTrait: (id: string, on: boolean) => void;
  className?: string;
};

export const TraitControl: FC<TraitControlProps> = ({
  category: { name, options },
  traits,
  updateTrait,
  className,
}) => {
  return (
    <CollapsableSection title={name} className={className}>
      <Fieldset>
        {options.map(({ value, count, id }) => (
          <Label key={value}>
            <TextWrap>
              <div>
                <Input
                  type="checkbox"
                  onChange={e => {
                    updateTrait(id, e.target.checked);
                  }}
                  checked={traits.has(id)}
                />{' '}
                {value}
              </div>
              {count}
            </TextWrap>
          </Label>
        ))}
      </Fieldset>
    </CollapsableSection>
  );
};
