import Web3 from 'web3';
import { api } from '.';
import { web3Util } from '../utils/web3Util';

export default async function setActiveHero(
  tokenId: string,
  account: string,
) {
  try {
    const timestamp = await fetch( `${process.env.REACT_APP_REQUEST_URL}/api/v1/timestamp`).then(res => res.text());
    const { signature } = await web3Util.getSignature(`Please sign this message to verify ownership of the selected wallet addres.\n\nTimestamp: ${timestamp}`);
    const res: any = await api<
      | {
            success: true
        }
      | { error: boolean }
    >('/api/game/v1/active-hero', {
      sig: signature,
      tokenId,
      timestamp,
      account
    });

    return !!res.success;
  } catch (e: any) {
    console.log(e.message);
  }
}
