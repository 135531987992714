import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { CollapsableSection, Input } from '../../components';
import { StatCategory } from '../../types';

const Row = styled.div`
  display: flex;
  gap: ${t => t.theme.space.s};
  padding: ${t => t.theme.s('0 l l')};
`;
const Fieldset = styled.fieldset`
  border: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: ${t => t.theme.space.xs};
`;
const Label = styled.label`
  color: ${t => t.theme.color.text.deemphesized};
`;
const ShortInput = styled(Input)`
  width: 6rem;
`;

export type StatControlProps = {
  category: StatCategory;
  stats: Map<string, [number, number]>;
  updateStat: (id: string, val: [number, number] | null) => void;
  className?: string;
};

export const StatControl: FC<StatControlProps> = ({
  category,
  stats,
  updateStat,
  className,
}) => {
  const [[min, max], setState] = useState(() => {
    const [min, max] = stats.get(category.name) ?? [category.min, category.max];
    return [`${min}`, `${max}`];
  });
  useEffect(() => {
    if (!stats.has(category.name))
      setState([`${category.min}`, `${category.max}`]);
  }, [stats, category]);

  const onBlur = () => {
    let minNum = parseInt(min);
    let maxNum = parseInt(max);
    if (Number.isNaN(minNum) || minNum < category.min) minNum = category.min;
    if (Number.isNaN(maxNum) || maxNum < category.max) maxNum = category.max;
    updateStat(category.name, [
      Math.max(category.min, minNum),
      Math.min(category.max, maxNum),
    ]);
    setState([`${minNum}`, `${maxNum}`]);
  };

  return (
    <CollapsableSection title={category.name} className={className}>
      <Row>
        <Fieldset>
          <Label>Min</Label>
          <ShortInput
            value={min}
            onChange={e => {
              setState([e.target.value, max]);
            }}
            onBlur={onBlur}
          />
        </Fieldset>
        <Fieldset>
          <Label>Max</Label>
          <ShortInput
            value={max}
            onChange={e => {
              setState([min, e.target.value]);
            }}
            onBlur={onBlur}
          />
        </Fieldset>
      </Row>
    </CollapsableSection>
  );
};
