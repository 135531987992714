import { web3Util } from '../utils/web3Util';
import { tx } from './tx';

export async function withdrawHearts(amount: string): Promise<string> {
  const stakingContract = web3Util.getContract('heartStaking');
  console.log(amount);
  return tx(stakingContract.methods.emergencyWithdraw());
}

export async function withdrawLP(amount: string): Promise<string> {
  const stakingContract = web3Util.getContract('lpStaking');
  return tx(stakingContract.methods.emergencyWithdraw());
}
