import { ReactNode } from 'react';
import styled from 'styled-components';

type InfoMsgProps = {
  children?: ReactNode;
};

const Wrap = styled.div`
  border: 3px solid rgba(223, 28, 28, 1);
  background: ${t => t.theme.color.bg.a};
  border-radius: ${t => t.theme.borderRadius.s};
  padding: 1rem;
`;

export function InfoMsg({ children }: InfoMsgProps) {
  return <Wrap>{children}</Wrap>;
}
