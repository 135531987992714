import React from 'react';
import useStore from '../dataStore/Store';
import { Button } from './Button';

import { web3Util } from '../utils/web3Util';

export default function ConnectWallet(props: {
  children?: React.ReactNode;
  text?: string;
}) {
  let { viewerAddress, updateViewerAddress } = useStore(
    ({ viewerAddress, updateViewerAddress }: any) => ({
      viewerAddress,
      updateViewerAddress,
    }),
  );

  const onClickConnect = async () => {
    if (!web3Util.hasProvider) {
      alert('Please download metamask to connect.');
      return;
    }
    try {
      await web3Util.enable();
      await updateViewerAddress(web3Util.accounts?.[0]);
    } catch (e: any) {
      console.log(e.message);
    }
  };
  return viewerAddress ? (
    <>{props.children}</>
  ) : (
    <Button onClick={onClickConnect}>{props.text || 'Connect wallet'}</Button>
  );
}
