import React, { FC, useState, useMemo, useEffect } from 'react';
import { RoutingContext, RoutingOptions } from '../contexts';
import { match, MatchFunction } from 'path-to-regexp';

export type Params = { [name: string]: string };
export type HandlerProps = {
  path: string;
  search: string;
  pathParams: Params;
  searchParams: Params;
};
export type RouteHandler = FC<HandlerProps>;
export type Route = [string, RouteHandler];
export type CompiledRoute = {
  match: MatchFunction;
  handler: RouteHandler;
};
export type RouterProps = {
  routes: Route[];
  NotFound: RouteHandler;
};

export const Router: FC<RouterProps> = ({ routes, NotFound }) => {
  const [{ path, search }, setState] = useState<{
    path: string;
    search: string;
  }>({ path: window.location.pathname, search: window.location.search });

  useEffect(() => {
    // list to pop state events and update
    const update = () => {
      setState({
        path: window.location.pathname,
        search: window.location.search,
      });
    };
    window.addEventListener('popstate', update);
    return () => window.removeEventListener('popstate', update);
  }, []);

  const compiledRoutes = useMemo(
    () =>
      routes.map(
        ([path, handler]: Route): CompiledRoute => ({
          match: match(path),
          handler,
        }),
      ),
    [routes],
  );

  const routingOptions = useMemo<RoutingOptions>(
    () => ({
      route(href: string): void {
        window.history.pushState(null, '', href);
        const url = new URL(href, window.location.origin);
        setState({ path: url.pathname, search: url.search });
      },
    }),
    [],
  );

  const { Component, props } = useMemo(() => {
    let compiledRoute;
    let match;
    for (let i = 0; !match && i < compiledRoutes.length; i++) {
      match = compiledRoutes[i].match(path);
      if (match) {
        compiledRoute = compiledRoutes[i];
      }
    }
    return {
      Component: compiledRoute?.handler ?? NotFound,
      props: {
        path,
        search,
        pathParams: (match ? match.params : {}) as Params,
        searchParams: Object.fromEntries(new URLSearchParams(search).entries()),
      },
    };
  }, [compiledRoutes, path, search, NotFound]);

  return (
    <RoutingContext.Provider value={routingOptions}>
      <Component key={path + search} {...props} />
    </RoutingContext.Provider>
  );
};
