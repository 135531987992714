import styled from 'styled-components';

export const SmOnly = styled.div`
  display: none;
  @media (max-width: ${t => t.theme.layout.minWidth}) {
    display: block;
  }
`;

export const LgOnly = styled.div`
  display: block;
  @media (max-width: ${t => t.theme.layout.minWidth}) {
    display: none;
  }
`;
