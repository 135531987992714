import React from 'react';
import styled, { keyframes } from 'styled-components';
import {
  Button,
  Col,
  DungeonAnnouncement,
  Heading,
  Layout,
  Link,
  P,
  Row,
} from '../../components';
import { MAX_WIDTH } from '../../style';
import { Grid } from '@mui/material';
import { DISCORD_LINK } from '../../config';
import { A } from '../../components/A';

const FadeUp = keyframes`
    0% {
        transform: translateY(10px);
        opacity: 0;
    }
    25% {
        opacity: 1;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
`;

const HeroImg = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 800px;
  max-height: calc(100vh - 80px - 37px);
  justify-content: center;
  padding: 10px;
`;

const DarkBg = styled.img`
  width: 100%;
  max-width: ${MAX_WIDTH};
  image-rendering: pixelated;
  object-fit: cover;
  position: absolute;
  pointer-events: none;
  width: 100%;
  height: 100%;
`;

const HeroLogo = styled.img`
  max-width: 1000px;
  width: 100%;
  image-rendering: pixelated;
  padding: 20px;
  opacity: 0;
  animation: ${FadeUp} 2s 0.333s ease-out forwards;
  z-index: 5;
  margin-bottom: 25px;
`;

type ContentWrapProps = { dark?: boolean };
const ContentWrap = styled.div`
  max-width: ${MAX_WIDTH};
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 4rem;

  ${(p: ContentWrapProps) => (p.dark ? 'background: #222222;' : '')}

  @media(max-width: 590px) {
    padding: 1rem;
  }
`;

export const IFrame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
`;

export const FrameContainer = styled.div`
  position: relative;
  flex: 1;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
`;

const BoldP = styled(P)`
  color: white;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1;
`;

const Img = styled.div`
  border-radius: 30px;
  overflow: hidden;
  max-width: 500px;
  margin: auto;
  & img {
    width: 100%;
  }
`;

const SmallContentWidth = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: auto;
`;

const HomePageDungeonAnnouncement = styled(DungeonAnnouncement)`
  position: relative;
  border-bottom: 0;
  height: 200px;
  & div {
    background: none;
  }
`;

const TextHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const HeroContent = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const HeroTitle = styled.div`
  font-size: 32px;
  color: #8eff59;
`;

const HeroSubTitle = styled.div`
  font-size: 18px;
  color: #8eff59;
  text-shadow: 4px 4px 4px #000;
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  object-position: center;
`;

export function Home() {
  return (
    <>
      <Layout fullWidth>
        <HeroImg>
          <DarkBg src={'/dark-bg.png'} />
          <Video loop autoPlay muted>
            <source src="/hero-movie-mob.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </Video>
          <DarkBg
            style={{
              maxWidth: '1000px',
              objectFit: 'contain',
              transform: 'translateY(-20%)',
            }}
            src="/merlins-dungeons.gif"
          />

          <HeroContent>
            <div
              style={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                justifyContent: 'flex-end',
                flexDirection: 'column',
                paddingBottom: '10rem',
              }}
            >
              <Heading>Merlin's Dungeon Pre-Alpha</Heading>
              <HeroSubTitle>Current build for Heroes only!</HeroSubTitle>
              <a
                style={{
                  display: 'inline-block',
                  marginTop: '20px',
                  width: '100%',
                  maxWidth: '250px',
                }}
                href="https://alpha.heroes.fun"
                target="_blank"
                rel="noreferrer"
              >
                <Button style={{ width: '100%' }}>Play Now</Button>
              </a>
            </div>
          </HeroContent>
        </HeroImg>

        <ContentWrap dark>
          <Grid container spacing={'1rem'}>
            <Grid item xs={12} md={6}>
              <FrameContainer>
                <IFrame
                  src="https://www.youtube.com/embed/Xr0YnfjZL1Y?rel=0"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></IFrame>
              </FrameContainer>
            </Grid>
            <Grid item xs={12} md={6}>
              <Heading>Merlin's Dungeons</Heading>
              <P>
                Embark on a thrilling journey through Merlin's Dungeons, where
                the fate of humanity rests in your hands. As one of the chosen
                3,333 Heroes, it is your destiny to defend against the looming
                threat of The Ghouls. Train your skills and hone your battle
                prowess by defeating the monsters that lurk within the dungeons.
                Prove your bravery and become the ultimate champion in this epic
                adventure, where the stakes are high and the rewards are even
                greater.
              </P>
              <a
                href="https://alpha.heroes.fun"
                target="_blank"
                rel="noreferrer"
              >
                <Button>Play Now</Button>
              </a>
            </Grid>
          </Grid>
        </ContentWrap>
        <Link href="/dungeons">
          <HomePageDungeonAnnouncement>
            <div>
              <TextHolder>
                <Heading>Dungeons Available Now</Heading>
                <Button>MINT A DUNGEON</Button>
              </TextHolder>
            </div>
            <img src="/embers-glow.png" />
          </HomePageDungeonAnnouncement>
        </Link>
        <ContentWrap>
          <Grid container spacing={'1rem'}>
            <Grid item xs={12} md={6}>
              <Heading>Weekly Dungeon Events</Heading>
              <P>
                Join upcoming Dungeon events and test your mettle! Each week,
                select dungeons will be chosen for players to explore and
                conquer. If your dungeon is chosen you'll share in the rewards
                as players battle through the challenges within. And for the top
                performers of the events, even more special rewards await! Don't
                miss out on this exciting opportunity to prove your bravery and
                earn wonderful treasures.
              </P>
              <BoldP>Events starting soon!</BoldP>
              <Link href="/dungeons">
                <Button>Get a Dungeon</Button>
              </Link>
            </Grid>
            <Grid item xs={12} md={6}>
              <Img>
                <img src="/dungeon-gif.gif" alt="Dungeons" />
              </Img>
            </Grid>
          </Grid>
        </ContentWrap>
        <img style={{ imageRendering: 'pixelated' }} src={'/hero-row.png'} />
        <ContentWrap>
          <SmallContentWidth>
            <Grid container spacing="1rem">
              <Grid item xs={12} md={6}>
                <div style={{ width: '100%', textAlign: 'center' }}>
                  <img
                    style={{ width: '100%', maxWidth: '265px' }}
                    src="/scroll.png"
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <Col style={{ height: '100%' }} justify="center">
                  <Heading>A prophecy foretold...</Heading>
                  <P>
                    3,333 heroes would come forth to fight for the soul of
                    humankind against an abhorrent evil. Those Heroes have
                    arrived.
                  </P>
                  <Row responsive gap="l">
                    <Col>
                      <a
                        href="https://opensea.io/collection/the-heroes-nft-collection"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Button>Browse OpenSea</Button>
                      </a>
                    </Col>
                  </Row>
                </Col>
              </Grid>
            </Grid>
          </SmallContentWidth>
        </ContentWrap>
        <ContentWrap>
          <Grid container spacing="1rem">
            <Grid item xs={12} md={6}>
              <Heading>Own your character and progress</Heading>
              <P>
                Become the ultimate Hero in Merlin's Dungeons and join the
                Heroes universe! Your Hero, stored on the Ethereum blockchain,
                was summoned with base stats, ready for you to play and level
                up. With exciting customizations to come, your Hero is a unique
                and valuable asset. Show off your skills in the dungeons and
                earn valuable rewards. You can sell or trade your Hero on third
                party marketplaces. Plus, each Hero receives a complimentary
                Ghoul NFT (minus transaction fees).
              </P>
            </Grid>
            <Grid item xs={12} md={6}>
              <Heading>Building open worlds</Heading>
              <P>
                Heroes are stored on the Ethereum blockchain and the art is
                licensed as CC0. Anyone can build on top of the Heroes core art
                and stats. When the Heroes were summoned their stats were stored
                directly on the blockchain and can be used by anyone for
                anything. We believe in the concept of open world creation and
                look forward to where it will take us.
              </P>
            </Grid>
          </Grid>
        </ContentWrap>

        <ContentWrap>
          <Row align="center" justify="center">
            <Col
              gap="s"
              align="center"
              style={{ width: '100%', maxWidth: '650px', textAlign: 'center' }}
            >
              <Heading>Developer Updates</Heading>
              <P style={{ marginTop: '0' }}>News from the team</P>
            </Col>
          </Row>

          <Row align="center" justify="center">
            <Col align="center" justify="center">
              <A
                href="https://hackmd.io/@deployer/heroes-nft-developer-update-2"
                target="_blank"
              >
                <P>3/1/23 Heroes Official Developer Update #2</P>
              </A>
              <A href="https://hackmd.io/@deployer/rJX7RF_3s" target="_blank">
                <P>2/1/23 Heroes Official Developer Update #1</P>
              </A>
              <A
                href="https://hackmd.io/@deployer/turning-ethereum-dungeons-into-playable-unity-levels"
                target="_blank"
              >
                <P>
                  1/30/23 Turning On-Chain Dungeon Layouts into Playable Unity
                  Levels
                </P>
              </A>
            </Col>
          </Row>
        </ContentWrap>
      </Layout>
    </>
  );
}
