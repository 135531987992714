import { api } from '.';
import { GetActiveHeroResponse } from '../types';

const getActiveHero = async (account: string) => {
  const { activeHero } = await api<GetActiveHeroResponse>(
    `/api/game/v1/active-hero/${account}`,
    void 0,
    { method: 'GET' }
  );
  return { activeHero };
};

export default getActiveHero;
