import React, { useCallback } from 'react';
import useStore from '../dataStore/Store';
import { TextButton } from './TextButton';

type SetActiveHeroButtonProps = {
    tokenId: string;
    style?: React.CSSProperties;
}

export function SetActiveHeroButton({ tokenId, style }: SetActiveHeroButtonProps) {
    const store = useStore();
    const onClick = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        store.setActiveHero(tokenId);
    }, []);
    return (
        <TextButton
        style={style}
            onClick={onClick}
        >Set as active Hero</TextButton>
    )
}