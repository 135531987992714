import React, { FC } from 'react';
import {
  ActivityTable,
  Col,
  HandlerProps,
  Heading,
  Layout,
  LoadingIndicator,
  PaginationControls,
} from '../../components';
import { dataStore } from '../../dataStore';
import { capitalize, parsePage, shortAddress, useAsyncData } from '../../utils';
import { WalletTabs } from '../WalletPage/WalletTabs';
import getActivity from '../../api/getActivity';

const RESULTS_PER_PAGE = 40;

export const ActivityPage: FC<HandlerProps> = ({
  pathParams: { collection, address, tokenId },
  searchParams: { page: pageString },
}) => {
  const page = parsePage(pageString);
  const type =
    address !== undefined
      ? 'wallet'
      : tokenId !== undefined
      ? 'token'
      : collection !== undefined
      ? 'collection'
      : 'global';
  const isViewer = address === dataStore.viewer();
  const title = capitalize(
    type === 'wallet'
      ? isViewer
        ? 'Your'
        : shortAddress(address)
      : type === 'token'
      ? `${collection} #${tokenId}`
      : type === 'collection'
      ? collection
      : '',
  );

  const [, , data] = useAsyncData(() =>
    getActivity({
      collection,
      tokenId,
      address,
      page,
      resultsPerPage: RESULTS_PER_PAGE,
    }),
  );

  return (
    <Layout>
      <Col align="stretch" gap="l">
        <Heading>{title} Activity</Heading>
        {type === 'wallet' && (
          <WalletTabs address={address} active="activity" />
        )}
        {data ? (
          <>
            <ActivityTable collection={collection} activity={data.results} />
            <PaginationControls
              page={page}
              resultsPerPage={RESULTS_PER_PAGE}
              totalResults={data.totalResults}
              basePath={`/collection/${collection}/activity`}
            />
          </>
        ) : (
          <LoadingIndicator />
        )}
      </Col>
    </Layout>
  );
};
