import styled from 'styled-components';

export type AProps = {
  disabled?: boolean | undefined;
};

export const A = styled.a<AProps>`
  color: ${({ theme, disabled }) =>
    disabled ? theme.color.text.disabled : theme.color.text.link};
  text-decoration: none;
  &:hover {
    color: ${({ theme, disabled }) =>
      disabled ? theme.color.text.disabled : theme.color.text.linkHover};
  }
`;
