import BigNumber from 'bignumber.js';
import { web3Util } from '../utils/web3Util';
import { tx } from './tx';

export default async function mintDungeon(amount: string, proof?: string[]) {
  const contract = web3Util.getContract('dungeonEthMinter');
  if (proof) {
    return tx(contract.methods.allowlistMint(proof, amount));
  } else {
    const value = new BigNumber(amount).times(0.019).times(10 ** 18).toFixed();
    return tx(contract.methods.mint(amount), { value  });
  }
}
