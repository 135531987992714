import { DefaultTheme } from 'styled-components';

export const MAX_WIDTH = '2000px';

export const theme = {
  layout: {
    leftColumnWidth: '20rem',
    mainContentWidth: '70rem',
    minWidth: '50rem',
  },
  font: {
    display: 'alagard',
    body: 'ubuntu-mono',
  },
  fontSize: {
    xs: '0.75rem',
    s: '1rem',
    m: '1.5rem',
    l: '2rem',
    xl: '4rem',
  },
  space: {
    xs: '0.5rem',
    s: '1rem',
    m: '1.5rem',
    l: '2rem',
    xl: '4rem',
    xxl: '6rem',
  },
  s(str: string): string {
    const keys = str.split(' ');
    if (keys.length < 1 || keys.length > 4) {
      throw new Error(`invalid key string '${str}'`);
    }
    return keys.reduce((memo, k, i) => {
      const v = k === '0' ? '0' : (this.space as { [k: string]: string })[k];
      if (!v) throw new Error(`invalid key string '${str}'`);
      return memo + (i > 0 ? ' ' : '') + v;
    }, '');
  },
  borderRadius: {
    xs: '4px',
    s: '8px',
    m: '14px',
  },
  color: {
    bg: {
      a: '#0d1320',
      b: '#161616',
      c: '#1a1610',
      d: '#434343',
    },
    border: {
      a: '#090806',
      b: '#979797',
    },
    text: {
      body: '#d8d4b7',
      link: '#f6da31',
      linkHover: '#ffffff',
      disabled: '#979797',
      deemphesized: '#535353',
      heading: '#8EFF59'
    },
  },
};
