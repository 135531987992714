import React, { FC, useEffect } from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { Router } from './components';
import AlertBar from './components/AlertBar';
import {
  ActivityPage,
  TokenPage,
  ListingsPage,
  NotFoundPage,
  StakingPage,
  TransactionPage,
  WalletPage,
  ClaimPage,
  DungeonsPage,
  Home,
} from './pages';
import { theme } from './style';
import { web3Util } from './utils/web3Util';

const GlobalStyle = createGlobalStyle`
  body, html {
    margin: 0;
    min-height: 100vh;
    background: ${t => t.theme.color.bg.c};
    font-family: ${t => t.theme.font.body};
    color: ${t => t.theme.color.text.body};
    scroll-behavior: smooth;

  }
  * {
    box-sizing: border-box;
  }
`;

export const App: FC = () => {
  useEffect(() => {
    if (window.localStorage?.getItem(web3Util.LS_KEY) === 'true') {
      try {
        web3Util.enable();
      } catch {}
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <AlertBar />
      <Router
        routes={[
          ['', Home],
          ['/listings', ListingsPage],
          ['/claim', ClaimPage],
          ['/collection/:collection', ListingsPage],
          ['/collection/:collection/activity', ActivityPage],
          ['/collection/:collection/:tokenId', TokenPage],
          ['/collection/:collection/:tokenId/activity', ActivityPage],
          ['/wallet/:address', WalletPage],
          ['/wallet/:address/listings', WalletPage],
          ['/wallet/:address/activity', ActivityPage],
          ['/staking', StakingPage],
          ['/dungeons', DungeonsPage],
          ['/transaction/:hash', TransactionPage],
        ]}
        NotFound={NotFoundPage}
      />
    </ThemeProvider>
  );
};

export default App;
