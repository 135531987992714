import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import claimAirdrop from '../../api/claimAirdrop';
import {
  Button,
  ExternalLink,
  Heading,
  Layout,
  Link,
  Price,
} from '../../components';
import ConnectWallet from '../../components/ConnectWallet';
import { RoutingContext } from '../../contexts';
import useStore from '../../dataStore/Store';
import { web3Util } from '../../utils/web3Util';

const Wrap = styled.div`
  max-width: 800px
  margin: auto;
`;

const Spacer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 50px;
`;

const SpacerAlt = styled(Spacer)`
  text-align: left;
  gap: 16px;

  justify-content: flex-start;
  align-items: flex-start;
`;

const HeadingAlt = styled(Heading)`
  margin-top: 20px;
`;

const SubHeading = styled.h2`
  font-size: 26px;
  margin: 0;
  letter-spacing: 1px;
`;

const Vision = styled.div`
  display: flex;
  gap: 20px;

  & div {
    flex: 1;
  }

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const Img = styled.div`
  border-radius: 30px;
  overflow: hidden;
  & img {
    width: 100%;
  }
`;

const P = styled.div`
  margin: 0;
`;

const HeadingRow = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 20px;

  align-items: center;
`;

export function ClaimPage() {
  const { viewerAddress } = useStore(s => ({
    viewerAddress: s.viewerAddress,
  }));
  const [claim, updateClaim] = useState<null | {
    proof: string[];
    leaf: string;
    amount: string;
  }>();
  const [hasClaimed, updateHasClaimed] = useState(false);
  const [loading, updateLoading] = useState(true);
  const { route } = useContext(RoutingContext);

  React.useEffect(() => {
    if (viewerAddress) {
      const fn = async () => {
        const proofs = await import('./proofs.json');
        // @ts-ignore
        const proof = proofs[viewerAddress.toLowerCase()];
        updateClaim(proof ? proof : null);
        if (proof) {
          const claimContract = web3Util.getContract('claim');
          const claimed = await claimContract.methods
            .claimedAddresses(viewerAddress)
            .call();
          updateHasClaimed(claimed);
        }
        updateLoading(false);
      };
      fn();
    }
  }, [viewerAddress]);

  const onClaim = async () => {
    try {
      if (!claim) return;
      const tx = await claimAirdrop(claim?.amount, claim?.proof);
      if (tx !== null) route(`/transaction/${tx}?next=${'/claim'}`);
    } catch {}
  };

  return (
    <Layout>
      <Wrap>
        <Spacer style={{ textAlign: 'center' }}>
          <Heading>The Heart Token</Heading>
          <ConnectWallet text="Connect wallet to claim">
            <>
              {loading && <span>Loading...</span>}
              {!loading && hasClaimed && claim && (
                <>
                  <div>
                    🧙‍♂️ You have successfully claimed{' '}
                    {web3Util.web3?.utils.fromWei(claim.amount) ?? '0'} Hearts!
                  </div>
                  <div>
                    <Link href="/staking">
                      <Button>Stake</Button>
                    </Link>
                  </div>
                  <div>
                    <Link href="/">
                      <Button>Browse Heroes</Button>
                    </Link>
                  </div>
                </>
              )}
              {!loading && !hasClaimed && (
                <>
                  {claim === null && (
                    <>
                      <div>You are not eligible to claim</div>
                      <div>
                        <ExternalLink href="https://app.uniswap.org/tokens/mainnet/0x710Aa623c2c881b0d7357bCf9aEedf660E606C22">
                          <Button>Buy Hearts</Button>
                        </ExternalLink>
                      </div>
                      <div>
                        <Link href="/">
                          <Button>Browse Heroes</Button>
                        </Link>
                      </div>
                    </>
                  )}
                  {claim && (
                    <>
                      <div>You are eligible to claim.</div>
                      <SubHeading>
                        <Price
                          price={
                            web3Util.web3?.utils.fromWei(claim.amount) ?? '0'
                          }
                        ></Price>
                      </SubHeading>
                      <Button onClick={onClaim} style={{ minWidth: '180px' }}>
                        Claim
                      </Button>
                    </>
                  )}
                </>
              )}
            </>
          </ConnectWallet>
        </Spacer>
        <Heading style={{ marginBottom: '20px' }}>The Vision</Heading>
        <Vision>
          <div>
            <Img>
              <img src="/map1_1200px.png" alt="map of fantasy world" />
            </Img>
          </div>
          <SpacerAlt>
            <HeadingAlt>In-Game Economy</HeadingAlt>
            <P>
              Use Hearts in a Hero Metaverse to summon new characters, craft
              items, learn skills, trade.
            </P>
            <HeadingAlt>NFT Discounts</HeadingAlt>
            <P>
              Use Hearts to purchase Hero world NFTs, like the upcoming Ghouls,
              land, property, items, clothing, and more at a discounted cost.
            </P>
            <HeadingRow>
              <Heading>Staking</Heading>
              <P>✅ LIVE</P>
            </HeadingRow>
            <P>
              Stake your Heart token to earn rewards. Reward pools are updated
              frequently.
            </P>
            <HeadingRow>
              <Heading>Marketplace</Heading>
              <P>✅ LIVE</P>
            </HeadingRow>
            <P>Buy and sell your Hero NFTs using Hearts.</P>
          </SpacerAlt>
        </Vision>
      </Wrap>
    </Layout>
  );
}
