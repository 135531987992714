import { Store } from './types';
import {
  FloorRequest,
  StakingRequest,
  ListingsRequest,
  ActivityRequest,
  InventoryRequest,
  GetInventoryResponse,
  CategoryResponse,
} from '../types';
import { activity, address, stakeInfo, tokens, txHash } from './mockData';
import create from 'zustand';
import { repeat } from '../utils';
import { web3Util } from '../utils/web3Util';
import { api } from '../api';
import approveForAll from '../api/approveForAll';
import setActiveHero from '../api/setActiveHero';
import getActiveHero from '../api/getActiveHero';

const delayedResponse = <Data>(
  data: Data,
  min = 200,
  max = 400,
): Promise<Data> =>
  new Promise(resolve =>
    setTimeout(() => resolve(data), min + Math.random() * (max - min)),
  );

type AlertObj = {
  type: 'success' | 'warning' | 'error';
  message: string;
  dismiss?: boolean;
};

type DataStoreType = {
  viewerAddress: string | undefined;
  inventory:
    | null
    | (GetInventoryResponse & { page: number; resultsPerPage: number });
  balance: string;
  approvedForAll: boolean;
  activeHero?:  null | string
  marketApprovedAmount: string | null;
  stakingApprovedAmount: string | null;
  lpStakingApprovedAmount: string | null;
  categories: CategoryResponse | null;
  alert?: AlertObj;
  updateAlert: (value?: AlertObj) => void;
  getInventory: (request: InventoryRequest) => Promise<void>;
  connectWallet: () => Promise<string | undefined>;
  updateViewerAddress: (address: string) => Promise<void>;
  getBalance: (address: string) => Promise<string>;
  setActiveHero: (tokenId: string) => Promise<void>;
  updateApprovedForAll: (value: boolean) => void;
  setApprovalForAll: () => Promise<string>;
  getCategories: () => Promise<void>;
  updateMarketApprovedAmount: (amount: string) => void;
  updateStakingApprovedAmount: (amount: string) => void;
  updateLPStakingApprovedAmount: (amount: string) => void;
};

const useStore = create<DataStoreType>((set, get) => ({
  // reads
  viewerAddress: void 0,
  inventory: null,
  categories: null,
  balance: '0',
  marketApprovedAmount: null,
  stakingApprovedAmount: null,
  lpStakingApprovedAmount: null,
  approvedForAll: false,

  updateAlert(value) {
    set({ alert: value });
  },

  async connectWallet() {
    try {
      await web3Util.enable();
      set({ viewerAddress: web3Util.accounts?.[0] });

      return web3Util.accounts?.[0] as string;
    } catch (e: any) {
      console.log(e.message);
    }
  },

  updateApprovedForAll(value: boolean) {
    set({ approvedForAll: value });
  },

  async setActiveHero(tokenId: string) {
    const store = get();
    if(!store.viewerAddress) {
      throw new Error('Viewer address not defined');
    }
    const success = await setActiveHero(tokenId, store.viewerAddress);
    if(success) {
      set({ activeHero: tokenId });
    }
  },

  async setApprovalForAll() {
    const s = get();
    const hash = await approveForAll(process.env.REACT_APP_MARKET as string);
    web3Util.pollTx?.watch(hash as string);
    const cb = () => {
      s.updateApprovedForAll(true);
      web3Util.pollTx?.off('completed', cb);
    };
    web3Util.pollTx?.on('completed', cb);
    return hash as string;
  },

  async updateViewerAddress(address: string) {
    set({ viewerAddress: address });
    await get().getBalance(address);
    const contract = web3Util.getContract('heroes');
    const isApprovedForAll = await contract.methods
      .isApprovedForAll(address, process.env.REACT_APP_MARKET)
      .call();
    get().updateApprovedForAll(isApprovedForAll);

    // get active hero 
    const { activeHero } = await getActiveHero(address);

    set({ activeHero });

    const hearts = web3Util.getContract('hearts');
    const lp = web3Util.getContract('lp');

    // check approvals
    const [
      marketApprovedAmount,
      stakingApprovedAmount,
      lpStakingApprovedAmount,
    ] = await Promise.all([
      hearts.methods.allowance(address, process.env.REACT_APP_MARKET).call(),
      hearts.methods
        .allowance(address, process.env.REACT_APP_HEART_STAKING)
        .call(),
      lp.methods.allowance(address, process.env.REACT_APP_LP_STAKING).call(),
    ]);
    get().updateMarketApprovedAmount(marketApprovedAmount);
    get().updateStakingApprovedAmount(stakingApprovedAmount);
    get().updateLPStakingApprovedAmount(lpStakingApprovedAmount);
  },

  updateMarketApprovedAmount(marketApprovedAmount: string) {
    set({ marketApprovedAmount });
  },

  updateActiveHero(tokenId: string) {
    set({ activeHero: tokenId });
  },

  updateStakingApprovedAmount(stakingApprovedAmount: string) {
    set({ stakingApprovedAmount });
  },

  updateLPStakingApprovedAmount(lpStakingApprovedAmount: string) {
    set({ lpStakingApprovedAmount });
  },

  async getBalance(address: string) {
    const contract = web3Util.getContract('hearts');
    const balance = await contract.methods.balanceOf(address).call();
    set({ balance: web3Util.web3?.utils.fromWei(balance.toString()) as string });
    return balance;
  },

  async getCategories() {
    const response = await api('/api/v1/categories', void 0, { method: 'GET' });
    set({ categories: response as CategoryResponse });
  },

  listings(request: ListingsRequest) {
    return delayedResponse({
      page: request.page ?? 1,
      totalResults: 184,
      results: repeat(request.resultsPerPage ?? 40, i => ({
        ...tokens[i % tokens.length],
        tokenId: `${i + 1}`,
        name: `Hero #${i + 1}`,
      })),
    });
  },

  async getInventory(request: InventoryRequest) {
    const { results, balance } = await api<GetInventoryResponse>(
      '/api/v1/inventory',
      request,
    );
    set({
      inventory: {
        results,
        balance,
        page: request.page ?? 1,
        resultsPerPage: request.resultsPerPage ?? 10,
      },
    });
  },

  // token(request: TokenRequest) {
  //   return delayedResponse(tokenDetail);
  // },

  // categories(request: CategoriesRequest) {
  //   return delayedResponse(categories);
  // },

  // activity(request: ActivityRequest) {
  //   return delayedResponse({
  //     page: 1,
  //     totalResults: 13,
  //     results: activity,
  //   });
  // },

  // floor(request: FloorRequest) {
  //   return delayedResponse({
  //     price: '1200',
  //     ethPrice: '0.35',
  //     totalListings: 1223,
  //   });
  // },

  // staking(request: StakingRequest) {
  //   return delayedResponse({
  //     rewardPoolBalance: '34023847',
  //     ethRewardPoolBalance: '3402385',
  //     token: stakeInfo,
  //     lpToken: stakeInfo,
  //   });
  // },

  // transaction(hash: string) {
  //   return delayedResponse(true, 2000, 3000);
  // },

  // // actions

  // async action() {
  //   // eslint-disable-next-line no-restricted-globals
  //   return confirm('Mocked metamask transaction') ? txHash : null;
  // },

  // buy(collection: string, tokenId: string) {
  //   return this.action();
  // },

  // list(collection: string, tokenId: string, price: string) {
  //   return this.action();
  // },

  // delist(collection: string, tokenId: string) {
  //   return this.action();
  // },

  // stakeToken(amount: string) {
  //   return this.action();
  // },

  // unstakeToken(amount: string) {
  //   return this.action();
  // },

  // claimToken() {
  //   return this.action();
  // },

  // stakeLP(amount: string) {
  //   return this.action();
  // },

  // unstakeLP(amount: string) {
  //   return this.action();
  // },

  // claimLP() {
  //   return this.action();
  // },
}));

export default useStore;

export class DataStore implements Store {
  // reads

  viewer() {
    return address;
  }

  walletBalance(address: string) {
    return delayedResponse('12300');
  }

  listings(request: ListingsRequest) {
    return delayedResponse({
      page: request.page ?? 1,
      totalResults: 184,
      results: repeat(request.resultsPerPage ?? 40, i => ({
        ...tokens[i % tokens.length],
        tokenId: `${i + 1}`,
        name: `Hero #${i + 1}`,
      })),
    });
  }

  inventory(request: InventoryRequest) {
    return delayedResponse({
      page: request.page ?? 1,
      totalResults: 184,
      results: tokens.map((t, i) => ({
        ...t,
        price: i % 2 === 0 ? t.price : undefined,
      })),
    });
  }

  // categories(request: CategoriesRequest) {
  //   return delayedResponse(categories);
  // }

  activity(request: ActivityRequest) {
    return delayedResponse({
      page: 1,
      totalResults: 13,
      results: activity,
    });
  }

  floor(request: FloorRequest) {
    return delayedResponse({
      price: '1200',
      ethPrice: '0.35',
      totalListings: 1223,
    });
  }

  staking(request: StakingRequest) {
    return delayedResponse({
      rewardPoolBalance: '34023847',
      ethRewardPoolBalance: '3402385',
      token: stakeInfo,
      lpToken: stakeInfo,
    });
  }

  transaction(hash: string) {
    return delayedResponse(true, 2000, 3000);
  }

  // actions

  private async action() {
    // eslint-disable-next-line no-restricted-globals
    return confirm('Mocked metamask transaction') ? txHash : null;
  }

  buy(collection: string, tokenId: string) {
    return this.action();
  }

  list(collection: string, tokenId: string, price: string) {
    return this.action();
  }

  delist(collection: string, tokenId: string) {
    return this.action();
  }

  stakeToken(amount: string) {
    return this.action();
  }

  unstakeToken(amount: string) {
    return this.action();
  }

  claimToken() {
    return this.action();
  }

  stakeLP(amount: string) {
    return this.action();
  }

  unstakeLP(amount: string) {
    return this.action();
  }

  claimLP() {
    return this.action();
  }
}
