import React, { ChangeEventHandler, FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div<{ disabled?: boolean }>`
  position: relative;
  border: solid 1px ${t => t.theme.color.border.a};
  background: ${t => t.theme.color.bg.b};
  color: ${t =>
    t.disabled ? t.theme.color.text.disabled : t.theme.color.text.body};
  height: 2rem;
  line-height: 2rem;
  padding: 0 ${t => t.theme.space.m};
  border-radius: ${t => t.theme.borderRadius.s};
  position: relative;
  &:active {
    color: ${t =>
      t.disabled ? t.theme.color.text.disabled : t.theme.color.text.linkHover};
  }
`;

const HiddenSelect = styled.select`
  appearance: none;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

export type SelectProps = {
  name: string;
  value: string;
  options: [string, string][];
  disabled?: boolean;
  onChange?: ChangeEventHandler<HTMLSelectElement>;
};

export const Select: FC<SelectProps> = ({
  value,
  options,
  disabled,
  onChange,
}) => (
  <Wrapper disabled={disabled}>
    {(options.find(([v]) => v === value) ?? [])[1]} ▼
    <HiddenSelect value={value} disabled={disabled} onChange={onChange}>
      {options.map(([v, text]) => (
        <option key={v} value={v}>
          {text}
        </option>
      ))}
    </HiddenSelect>
  </Wrapper>
);
