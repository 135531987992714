import React, { FC, useState } from 'react';
import styled from 'styled-components';

const Main = styled.div``;
const Title = styled.div`
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${t => t.theme.s('0 l')};
`;
const Icon = styled.button`
  border: none;
  background: transparent;
  margin: 0;
  padding: 0;
  color: ${t => t.theme.color.text.body};
`;

export type CollapsableSectionProps = {
  title: string;
  className?: string;
};

export const CollapsableSection: FC<CollapsableSectionProps> = ({
  title,
  children,
  className,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Main className={className}>
      <Title onClick={() => setOpen(!open)}>
        {title}
        <Icon>{open ? '-' : '+'}</Icon>
      </Title>
      {open && children}
    </Main>
  );
};
