import React, { ChangeEventHandler, FC } from 'react';
import styled from 'styled-components';
import { Input } from './Input';
import { Button } from './Button';

const Form = styled.form`
  display: flex;
  position: relative;
  border-radius: ${t => t.theme.borderRadius.m};
  border: solid 1px ${t => t.theme.color.border.a};
`;
const JoinedInput = styled(Input)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: none;
  flex: 1;
  &:focus {
    z-index: 2;
  }
`;
const SecondaryButton = styled(Button)`
  background: #eee;
  color: #111;
  padding: 0.5rem;
  border: 0;
  border-radius: 0;
  &:focus {
    z-index: 2;
  }
  &:hover {
    color: #444;
  }
`;
const JoinedButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: 0;
  border-left: solid 1px ${t => t.theme.color.border.a};
  width: 8rem;
  &:focus {
    z-index: 2;
  }
`;

export type LinkedInputProps = {
  onSubmit: () => void;
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  buttonText: string;
  secondaryButtonText?: string;
  disabled?: boolean;
  placeholder?: string;
  onSecondaryButtonClick?: () => void;
};

export const LinkedInput: FC<LinkedInputProps> = ({
  onSubmit,
  value,
  onChange,
  buttonText,
  disabled,
  secondaryButtonText,
  onSecondaryButtonClick,
  placeholder,
}) => (
  <Form
    onSubmit={e => {
      e.preventDefault();
      onSubmit();
    }}
  >
    <JoinedInput
      placeholder={placeholder}
      type="text"
      value={value}
      disabled={disabled}
      onChange={onChange}
    />
    {secondaryButtonText !== undefined &&
      onSecondaryButtonClick !== undefined && (
        <SecondaryButton
          onClick={e => {
            e.preventDefault();
            onSecondaryButtonClick();
          }}
        >
          {secondaryButtonText}
        </SecondaryButton>
      )}
    <JoinedButton type="submit">{buttonText}</JoinedButton>
  </Form>
);
