import { web3Util } from '../utils/web3Util';

export const tx = (method: any, sendParams?: any): Promise<string> => {
  return new Promise((resolve, reject) =>
    method
      .send({ from: web3Util.accounts?.[0], ...sendParams })
      .on('transactionHash', (hash: string) => {
        resolve(hash);
      })
      .on('error', (e: unknown) => reject(e)),
  );
};
