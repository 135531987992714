import React, { FC } from 'react';
import styled from 'styled-components';
import { repeat } from '../utils';

const Main = styled.div`
  display: flex;
  gap: 0.5em;
  align-items: center;
`;
const Image = styled.img`
  aspect-ratio: 1;
  height: 1em;
`;
const EthPrice = styled.div`
  color: ${t => t.theme.color.text.deemphesized};
  font-size: ${t => t.theme.fontSize.s};
  margin-top: 0.5em;
`;

export type PriceProps = {
  price: string;
  ethPrice?: string;
  numHearts?: number;
  className?: string;
  icon?: boolean;
  iconImg?: React.ReactNode;
};

export const Price: FC<PriceProps> = ({
  price,
  iconImg,
  ethPrice,
  className,
  numHearts = 1,
  icon = true,
}) => {
  return (
    <div className={className}>
      <Main>
        {iconImg}
        {icon && repeat(numHearts, i => <Image key={i} src="/heart.svg" />)}
        {price}
      </Main>
      {ethPrice && <EthPrice>= {ethPrice} ETH</EthPrice>}
    </div>
  );
};
