import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { claimHearts, claimLP } from '../../api/claim';
import { stakeHearts, stakeLP } from '../../api/stake';
import staking from '../../api/staking';
import { withdrawHearts, withdrawLP } from '../../api/withdraw';
import {
  Heading,
  Layout,
  LoadingIndicator,
  Price,
  Col,
  ExternalLink,
} from '../../components';
import {
  DISCORD_LINK,
  EXCHANGE_LINK,
  EXCHANGE_LP_TOKEN_LINK,
} from '../../config';
import { RoutingContext } from '../../contexts';
import useStore from '../../dataStore/Store';
import { useAsyncData } from '../../utils';
import { StakingCard } from './StakingCard';
import BN from 'bignumber.js';
import {
  APPROVAL_AMOUNT,
  approveHeartsStaking,
  approveLPStaking,
} from '../../api/approveStaking';
import { InfoMsg } from '../../components/InfoMsg';
import { A } from '../../components/A';
import ConnectWallet from '../../components/ConnectWallet';

const Container = styled.div`
  width: 100%;
  max-width: ${t => t.theme.layout.mainContentWidth};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: ${t => t.theme.space.l};
`;
const Info = styled.div`
  max-width: 30rem;
`;
const Columns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${t => t.theme.space.l};

  @media (max-width: ${t => t.theme.layout.minWidth}) {
    grid-template-columns: 1fr;
    gap: ${t => t.theme.space.xl};
  }
`;

const Image = styled.img`
  height: 2em;
  width: auto;
  image-rendering: pixelated;
`;

export const StakingPage: FC = () => {
  const store = useStore();
  const { viewerAddress } = store;
  const { route } = useContext(RoutingContext);
  const [, , data] = useAsyncData(
    async () => viewerAddress && staking(viewerAddress),
    [viewerAddress],
  );

  const isApproved =
    store.stakingApprovedAmount &&
    new BN(store.stakingApprovedAmount).gt(120_000_000e18);

  const isLPApproved =
    store.lpStakingApprovedAmount &&
    new BN(store.lpStakingApprovedAmount).gt(120_000_000e18);

  console.log(viewerAddress);

  return (
    <Layout>
      <Container>
        <InfoMsg>
          The staking program has ended. Please withdraw all tokens.
          <br />
          Join our <ExternalLink href={DISCORD_LINK}>Discord</ExternalLink> if
          you have any questions.
        </InfoMsg>
        {!viewerAddress ? (
          <div>
            <Heading>Stake Your Hearts</Heading>
            <p>Please connect wallet to withdraw</p>
            <ConnectWallet />
          </div>
        ) : data ? (
          <>
            <Columns>
              <Col gap="l" align="stretch">
                <StakingCard
                  tokenName="Heart"
                  linkText="Get Heart Token"
                  linkUrl={EXCHANGE_LINK}
                  data={data.token}
                  isApproved={!!isApproved}
                  approve={() => {
                    approveHeartsStaking().then(tx => {
                      if (tx !== null) {
                        route(`/transaction/${tx}?next=/staking`);
                        store.updateStakingApprovedAmount(APPROVAL_AMOUNT);
                      }
                    });
                  }}
                  stake={amount => {
                    stakeHearts(amount).then(tx => {
                      if (tx !== null)
                        route(`/transaction/${tx}?next=/staking`);
                    });
                  }}
                  unstake={amount => {
                    withdrawHearts(amount).then(tx => {
                      if (tx !== null)
                        route(`/transaction/${tx}?next=/staking`);
                    });
                  }}
                  claim={() => {
                    claimHearts().then(tx => {
                      if (tx !== null)
                        route(`/transaction/${tx}?next=/staking`);
                    });
                  }}
                />
                <StakingCard
                  tokenName="LP Token"
                  linkText="Get Sushi Heart LP Token"
                  info="Claimed Hearts will be automatically sent to your wallet."
                  linkUrl={EXCHANGE_LP_TOKEN_LINK}
                  icon={false}
                  iconImg={
                    <Image
                      style={{ imageRendering: 'auto', height: '1.35em' }}
                      src="/sushi.png"
                    />
                  }
                  data={data.lpToken}
                  isApproved={!!isLPApproved}
                  approve={() => {
                    approveLPStaking().then(tx => {
                      if (tx !== null)
                        route(`/transaction/${tx}?next=/staking`);
                      store.updateLPStakingApprovedAmount(APPROVAL_AMOUNT);
                    });
                  }}
                  stake={amount => {
                    stakeLP(amount).then(tx => {
                      if (tx !== null)
                        route(`/transaction/${tx}?next=/staking`);
                    });
                  }}
                  unstake={amount => {
                    withdrawLP(amount).then(tx => {
                      if (tx !== null)
                        route(`/transaction/${tx}?next=/staking`);
                    });
                  }}
                  claim={() => {
                    claimLP().then(tx => {
                      if (tx !== null)
                        route(`/transaction/${tx}?next=/staking`);
                    });
                  }}
                />
              </Col>
            </Columns>
          </>
        ) : (
          <LoadingIndicator />
        )}
      </Container>
    </Layout>
  );
};
