import React, { FC } from 'react';
import styled from 'styled-components';
import { Token } from '../types';
import { TokenCard } from './TokenCard';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  gap: ${t => t.theme.space.l};
  margin-bottom: ${t => t.theme.space.xl};
`;

export type TokenGridProps = {
  tokens: Token[];
};

export const TokenGrid: FC<TokenGridProps> = ({ tokens }) => {
  return (
    <Grid>
      {tokens.map(token => {
        return <TokenCard key={token.tokenId} token={token} />;
      })}
    </Grid>
  );
};
