import styled, { keyframes } from 'styled-components';

export const pulse = keyframes`
  from {
    transform: scale(0.75);
  }
  to {
    transform: scale(1);
  }
`;

export const LoadingIndicator = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: ${t => t.theme.color.text.link};
  animation: ${pulse} 1s linear infinite alternate;
  z-index: 1;
`;
