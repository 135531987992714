import React, { FC, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  ExternalLink,
  Heading,
  HR,
  Price,
  Row,
  Col,
} from '../../components';
import { LinkedInput } from '../../components/LinkedInput';
import { StakeInfo } from '../../types';
import BN from 'bignumber.js';

const Card = styled.div`
  border-radius: ${t => t.theme.borderRadius.m};
  background: ${t => t.theme.color.bg.b};
  border: solid 1px ${t => t.theme.color.border.a};
`;
const Tabs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: solid 1px ${t => t.theme.color.border.a};
`;
const Tab = styled.button<{ active: boolean }>`
  appearance: none;
  border: none;
  background: ${t => (t.active ? t.theme.color.bg.a : 'transparent')};
  color: ${t =>
    t.active ? t.theme.color.text.link : t.theme.color.text.deemphesized};
  text-align: center;
  padding: ${t => t.theme.space.s};
  &:first-child {
    border-right: solid 1px ${t => t.theme.color.border.a};
  }
`;

export type StakingCardProps = {
  tokenName: string;
  linkText: string;
  linkUrl: string;
  icon?: boolean;
  info?: string;
  iconImg?: React.ReactNode;
  data: StakeInfo;
  isApproved: boolean;
  approve: () => void;
  stake: (amount: string) => void;
  unstake: (amount: string) => void;
  claim: () => void;
};

export const StakingCard: FC<StakingCardProps> = ({
  tokenName,
  linkText,
  linkUrl,
  iconImg,
  info,
  icon = true,
  data: {
    apy,
    totalStakedBalance,
    walletBalance,
    stakedBalance,
    unclaimedRewards,
  },
  data,
  isApproved,
  approve,
  stake,
  unstake,
  claim,
}) => {
  const [tab, setTab] = useState<'Stake' | 'Unstake'>('Unstake');
  const [value, setValue] = useState('');

  return (
    <Col gap="s" align="stretch">
      <Heading>{tokenName} Staking</Heading>

      <Card>
        <Col gap="s" align="stretch">
          <LinkedInput
            onSubmit={() => {
              const fixedValue = new BN(value).times(10 ** 18).toFixed();
              return unstake(fixedValue);
            }}
            value={stakedBalance}
            disabled
            onChange={e => setValue(e.target.value)}
            buttonText={tab}
            secondaryButtonText="MAX"
            onSecondaryButtonClick={() =>
              setValue(tab === 'Stake' ? walletBalance : stakedBalance)
            }
          />
          <Row justify="space-between">
            {tokenName}s in wallet{' '}
            <Price iconImg={iconImg} icon={icon} price={walletBalance} />
          </Row>
          <Row justify="space-between">
            Your amount staked{' '}
            <Price iconImg={iconImg} icon={icon} price={stakedBalance} />
          </Row>
        </Col>
      </Card>
    </Col>
  );
};
