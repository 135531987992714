import React, { FC } from 'react';
import { Row } from '.';
import { Link } from './Link';

export type TabsProps = {
  tabs: { href: string; text: string; active: boolean }[];
};

export const Tabs: FC<TabsProps> = ({ tabs }) => {
  return (
    <Row as="nav" gap="l">
      {tabs.map(({ href, text, active }, i) => (
        <Link key={i} href={href} disabled={active}>
          {text}
        </Link>
      ))}
    </Row>
  );
};
