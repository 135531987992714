import React, { useEffect } from 'react';
import styled from 'styled-components';
import useStore from '../dataStore/Store';

const Bar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 35px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props: { type: any }) => {
    if (props.type === 'success') return 'green';
    if (props.type === 'warning') return 'orange';
    if (props.type === 'error') return 'red';
  }};
`;

export default function AlertBar() {
  const store = useStore();

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (store.alert?.dismiss) {
      timeout = setTimeout(() => {
        store.updateAlert(void 0);
      }, 4000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [store, store.alert]);
  return store.alert ? (
    <Bar type={store.alert?.type}>
      {store.alert?.message}
      <span
        onClick={() => store.updateAlert(void 0)}
        style={{ cursor: 'pointer', position: 'absolute', right: '30px' }}
      >
        x
      </span>
    </Bar>
  ) : null;
}
