import React, { FC, useContext } from 'react';
import { RoutingContext } from '../contexts';
import { A } from './A';

export type LinkProps = {
  href: string;
  className?: string;
  disabled?: boolean;
};

export const Link: FC<LinkProps> = ({
  href,
  children,
  className,
  disabled,
}) => {
  const { route } = useContext(RoutingContext);

  return (
    <A
      href={disabled ? undefined : href}
      onClick={
        disabled
          ? undefined
          : e => {
              if (e.metaKey || e.button !== 0) return;
              e.preventDefault();
              route(href);
            }
      }
      className={className}
      disabled={disabled}
    >
      {children}
    </A>
  );
};
