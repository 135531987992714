import styled from 'styled-components';

export const Col = styled.div<{
  gap?: 'xs' | 's' | 'm' | 'l' | 'xl';
  justify?: string;
  align?: string;
  padding?: string;
  flex?: string;
}>`
  display: flex;
  flex: ${t => (t.flex !== undefined ? t.flex : '0 1 auto')};
  flex-direction: column;
  align-items: ${t => t.align ?? 'flex-start'};
  justify-content: ${t => t.justify ?? 'flex-start'};
  gap: ${t => (t.gap === undefined ? 0 : t.theme.space[t.gap])};
  padding: ${t => (t.padding === undefined ? 0 : t.theme.s(t.padding))};
`;
