import { provider } from 'web3-core';
import Web3 from 'web3';
import heroAbi from './abis/heroes.json';
import heartsAbi from './abis/hearts.json';
import marketAbi from './abis/market.json';
import stakingAbi from './abis/staking.json';
import stakeAbi from './abis/stake.json';
import claimAbi from './abis/claim.json';
import dungeonAbi from './abis/dungeons.json';
import dungeonEthMinterAbi from './abis/dungeonEthMinter.json';

type EventCallback = (...args: any[]) => void;

class Emitter {
  events: {
    [eventName: string]: EventCallback[];
  };

  constructor() {
    this.events = {};
  }

  on(name: string, fn: EventCallback) {
    const event = this.events[name];
    if (event) {
      this.events[name].push(fn);
    } else {
      this.events[name] = [fn];
    }
  }

  off(name: string, fn: EventCallback) {
    if (this.events[name]) {
      this.events[name] = this.events[name].filter(evtFn => evtFn !== fn);
    }
  }

  once(name: string, fn: EventCallback) {
    // @ts-ignore
    fn.__once__ = true;
    this.on(name, fn);
  }

  removeAllListeners(name: string) {
    if (this.events[name]) {
      delete this.events[name];
    }
  }

  emit(name: string, ...args: any[]) {
    const event = this.events[name];
    if (Array.isArray(event)) {
      event.forEach((fn, i) => {
        fn(...args);
        // @ts-ignore
        if (fn.__once__) {
          event.splice(i, 1);
        }
      });
    }
  }
}

const getBlock = (web3: Web3, ...args: any[]): Promise<number> =>
  new Promise(resolve => {
    // @ts-ignore
    web3.eth.getBlock(...args, (err: {}, block: { number: number }) => {
      resolve(err || !block ? -1 : block.number);
    });
  });

class PollTx extends Emitter {
  pending: string[];

  completed: string[];

  watching: boolean;

  web3: Web3;

  constructor(web3: Web3) {
    super();
    this.web3 = web3;
    this.pending = [];
    this.completed = [];
    this.watching = false;
  }

  // Public function that is called when the user wants to watch a transaction
  public watch(tx: string) {
    this.pending.push(tx);
    if (!this.watching) {
      this.startWatching();
    }
    // An event which is emitted with the updated list of pending transactions
    this.emit('pending', tx, this.pending);
  }

  getTransaction = (hash: string) =>
    new Promise((resolve, reject) => {
      this.web3.eth.getTransaction(hash, (err, data) => {
        if (err) {
          return reject(err);
        } else {
          return resolve(data);
        }
      });
    });

  private internalWatch = async () => {
    if (!this.watching) {
      return;
    }
    const txData = await Promise.all(
      this.pending.map(tx => this.getTransaction(tx)),
    );

    // @ts-ignore
    const completed = txData.filter(tx => !!tx && tx.blockNumber != null);

    if (completed.length > 0) {
      await Promise.all(
        completed.map(tx =>
          (async () => {
            const { blockNumber } = tx as { blockNumber: number };
            let block;

            try {
              block = await getBlock(this.web3, 'latest');
            } catch (e: any) {
              console.error(e.message);
            }

            if (block && block - blockNumber >= 0) {
              this.completedFn(tx as any);
            }
          })(),
        ),
      );
    }

    window.setTimeout(this.internalWatch, 1000);
  };

  // Internal function that begins to watch transactions in pending array
  startWatching() {
    if (this.watching) return;
    this.watching = true;
    this.internalWatch();
  }

  // Internal function that is called when a transaction has been completed
  completedFn(tx: { hash: string }) {
    // Remove completed transaction from pending array
    this.pending = this.pending.filter(t => t !== tx.hash);
    this.completed.push(tx.hash);
    // An even which is emitted upon a completed transaction
    this.emit('completed', tx.hash, this.pending);
    if (this.pending.length === 0) {
      this.watching = false;
    }
  }
}

const contracts = {
  heroes: {
    address: process.env.REACT_APP_HEROES,
    abi: heroAbi,
  },
  dungeonEthMinter: {
    address: "0x61b431A91dFeb0d1C67405f4B2D7aa82B94C80E9",
    abi: dungeonEthMinterAbi,
  },
  market: {
    address: process.env.REACT_APP_MARKET,
    abi: marketAbi,
  },
  staking: {
    address: process.env.REACT_APP_STAKING,
    abi: stakingAbi,
  },
  hearts: {
    address: process.env.REACT_APP_HEARTS,
    abi: heartsAbi,
  },
  lp: {
    address: process.env.REACT_APP_LP,
    abi: heartsAbi,
  },
  heartStaking: {
    address: process.env.REACT_APP_HEART_STAKING,
    abi: stakeAbi,
  },
  lpStaking: {
    address: process.env.REACT_APP_LP_STAKING,
    abi: stakeAbi,
  },
  claim: {
    address: process.env.REACT_APP_CLAIM,
    abi: claimAbi,
  },
  dungeons: {
    address: process.env.REACT_APP_DUNGEONS,
    abi: dungeonAbi,
  },
};

// @ts-ignore
window.contracts = contracts;

type ContractName = keyof typeof contracts;

export class Web3Util extends Emitter {
  hasProvider: boolean;
  provider: provider;
  enabled = false;
  accounts?: string[];
  pollTx?: PollTx;
  web3?: Web3;

  constructor() {
    super();
    this.provider =
      // @ts-ignore
      window['ethereum'] || (window.web3 && window.web3.currentProvider);
    this.hasProvider = !!this.provider;
  }

  _setWeb3 = () => {
    if (this.provider) {
      const web3 = new Web3(this.provider);
      this.pollTx = new PollTx(web3);

      this.web3 = web3;
      //@ts-ignore
      window.web3 = web3;
      this.pollTx = new PollTx(web3);
      return true;
    } else {
      return false;
    }
  };

  getContract = (name: ContractName) => {
    const { abi, address } = contracts[name];
    if (!this.web3) {
      throw new Error('Web3 must be initialized');
    }
    return new this.web3.eth.Contract(abi as any, address);
  };

  LS_KEY = '__ENABLED__';

  getSignature = async (
    message: string,
  ): Promise<{
    address: string;
    signature: string;
  }> => {
    if (!this.accounts || !this.web3) throw new Error();
    const address = this.accounts[0];

    const signature = await this.signMessage(address, message);

    return {
      address,
      signature,
    };
  };

  private signMessage(
    address: string,
    signingMessage: string,
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      if (!this.web3 || !(this.accounts?.[0] === address)) return reject();
      this.web3.eth.personal.sign(
        signingMessage,
        address,
        // @ts-ignore
        (err: Error, result: any) => {
          if (err) return reject(new Error(err.message));
          if (result.error) {
            return reject(new Error(result.error.message));
          }
          resolve(result);
        },
      );
    });
  }

  enable = async () => {
    if (this._setWeb3() && !this.enabled) {
      // @ts-ignore
      if (this.provider && this.provider.isMetaMask) {
        window.localStorage?.setItem(this.LS_KEY, 'true');
        // @ts-ignore
        window.ethereum.on('accountsChanged', accounts => {
          this.accounts = accounts;
          this.emit('accountsUpdated', this.accounts);
        });
      }

      if (this.provider) {
        if (!this.web3) throw new Error();
        // @ts-ignore
        const accounts = await window.ethereum.enable();
        // @ts-ignore
        const windowWeb3 = window.web3.eth.accounts;
        this.accounts = accounts || windowWeb3;
        this.emit('accountsUpdated', this.accounts);
      } else {
        // @ts-ignore
        this.accounts = window.web3.eth.accounts;
      }
      if (!this.accounts) {
        throw new Error('Accounts is undefined. User cancelled');
      }
      if (!this.enabled) {
        this.emit('enabled');
      }
      this.enabled = true;
    }
  };
}

export const web3Util = new Web3Util();

// @ts-ignore
window.web3Util = web3Util;
