import React, { FC } from 'react';
import styled from 'styled-components';
import { StatValue } from '../../types';

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${t => t.theme.space.l};
`;
const Labels = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 11px;
`;
const Bar = styled.div`
  height: 15px;
  border: solid 1px ${t => t.theme.color.border.a};
  background: ${t => t.theme.color.bg.b};
  position: relative;
`;
const BarInner = styled.div`
  height: 100%;
  background: ${t => t.theme.color.bg.d};
`;

export type StatsProps = {
  stats: StatValue[];
};

export const Stats: FC<StatsProps> = ({ stats }) => {
  return (
    <Main>
      {stats.map(({ type, value }) => (
        <div key={type}>
          <Labels>
            <span>{type}</span>
            <span>
              {value}/{1000}
            </span>
          </Labels>
          <Bar>
            <BarInner style={{ width: `${(value / 1000) * 100}%` }} />
          </Bar>
        </div>
      ))}
    </Main>
  );
};
