import React, { FC, useContext, useEffect, useState } from 'react';
import {
  ExternalLink,
  HandlerProps,
  Heading,
  Layout,
  Link,
  LoadingIndicator,
  Router,
} from '../../components';
import { shortAddress } from '../../utils';
import { web3Util } from '../../utils/web3Util';
import { ETHERSCAN_TRANSACTION_URL } from '../../config';
import { RoutingContext } from '../../contexts';
import useStore from '../../dataStore/Store';

export const TransactionPage: FC<HandlerProps> = ({
  pathParams: { hash },
  searchParams: { next },
}) => {
  const [status, setStatus] = useState<'pending' | 'failed' | 'complete'>(
    'pending',
  );
  const { route } = useContext(RoutingContext);

  useEffect(() => {
    web3Util.enable();

    let canceled = false;
    const cb = (h: string) => {
      if (!canceled) {
        setStatus('complete');
        if (next) {
          route(`${next}${window.location.hash}`);
        }
      }
    };
    web3Util.pollTx?.watch(hash);
    web3Util.pollTx?.on('completed', cb);
    return () => {
      canceled = true;
      web3Util.pollTx?.off('completed', cb);
    };
  }, [hash]);

  return (
    <Layout>
      <Heading>Transaction {shortAddress(hash)}</Heading>
      {status === 'pending' ? (
        <>
          <p>Please wait for your transaction to be confirmed</p>
          <LoadingIndicator />
        </>
      ) : status === 'complete' ? (
        <>
          <p>Transaction successful</p>
          <Link href={next ?? '/'}>Continue</Link>
        </>
      ) : (
        <>
          <p>Transaction failed</p>
          <Link href={next ?? '/'}>Continue</Link>
        </>
      )}
      <p>
        <ExternalLink href={`${ETHERSCAN_TRANSACTION_URL}${hash}`}>
          view on etherscan
        </ExternalLink>
      </p>
    </Layout>
  );
};
