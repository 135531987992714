import React, { FC } from 'react';
import { Tabs } from '../../components';

export type WalletTabsProps = {
  address: string;
  active: 'inventory' | 'listings' | 'activity';
};

export const WalletTabs: FC<WalletTabsProps> = ({ address, active }) => (
  <Tabs
    tabs={[
      {
        href: `/wallet/${address}`,
        text: 'Inventory',
        active: active === 'inventory',
      },
      {
        href: `/wallet/${address}/listings`,
        text: 'Listings',
        active: active === 'listings',
      },
      // {
      //   href: `/wallet/${address}/activity`,
      //   text: 'Activity',
      //   active: active === 'activity',
      // },
    ]}
  />
);
