import { StakeInfo, Token } from '../types';

export const viewerAddress = '0x71C7656EC7ab88b098defB751B7401B5f6d8976F';
export const address = '0xd8dA6BF26964aF9D7eEd9e03E53415D37aA96045';
export const txHash =
  '0x7a91b275e238657f24a288a3ad586643865d5a3db5f605947b3c3586ef5e8092';

export const tokens: Token[] = [
  {
    name: 'Hero #12',
    image: '/hero1.png',
    tokenId: '12',
    price: '1200',
    collection: 'heroes',
    owner: address,
  },
  {
    name: 'Hero #17',
    image: '/hero2.png',
    tokenId: '17',
    price: '1300',
    collection: 'heroes',
    owner: address,
  },
  {
    name: 'Hero #29',
    image: '/hero3.png',
    tokenId: '29',
    price: '3400',
    collection: 'heroes',
    owner: address,
  },
  {
    name: 'Hero #30',
    image: '/hero4.png',
    tokenId: '30',
    price: '3400',
    collection: 'heroes',
    owner: address,
  },
  {
    name: 'Hero #35',
    image: '/hero5.png',
    tokenId: '35',
    price: '3400',
    collection: 'heroes',
    owner: address,
  },
  {
    name: 'Hero #599',
    image: '/hero6.png',
    tokenId: '599',
    price: '3900',
    collection: 'heroes',
    owner: address,
  },
];

export const tokenDetail = {
  name: 'Hero #599',
  collection: 'heroes',
  tokenId: '599',
  image: '/hero6.png',
  price: '1200',
  ethPrice: '0.6',
  collectionDisplayName: 'Heroes',
  traits: [
    { category: 'Race', name: 'Frogling', percent: 3 },
    { category: 'Hat', name: 'Helmet of Power', percent: 19 },
  ],
  stats: [
    { name: 'Creativity', max: 1000, value: 700 },
    { name: 'Dexterity', max: 1000, value: 500 },
  ],
  owner: address,
};

export const categories = {
  traits: [
    {
      name: 'Clothes',
      options: [
        { id: 'a', displayName: 'Blue Vest', count: 99 },
        { id: 'b', displayName: 'Tunic of Wonder', count: 31 },
        { id: 'c', displayName: 'Tunic of Mystery', count: 9 },
        { id: 'd', displayName: 'Shirt of Emerald', count: 18 },
      ],
    },
    {
      name: 'Head',
      options: [
        { id: 'e', displayName: 'Purple Hat', count: 99 },
        { id: 'f', displayName: 'Helmet of Wonder', count: 21 },
        { id: 'g', displayName: 'Tunic of Power', count: 102 },
      ],
    },
    { name: 'Pants', options: [] },
    { name: 'Race', options: [] },
    { name: 'Shield', options: [] },
    { name: 'Shoes', options: [] },
    { name: 'Weapon', options: [] },
  ],
  stats: [
    { name: 'Creativity', min: 1, max: 1000 },
    { name: 'Dexterity', min: 1, max: 1000 },
    { name: 'Intelligence', min: 1, max: 1000 },
    { name: 'Magic', min: 1, max: 1000 },
    { name: 'Stamina', min: 1, max: 1000 },
    { name: 'Strength', min: 1, max: 1000 },
  ],
};

export const activity = [
  {
    name: 'Hero #30',
    image: '/hero4.png',
    tokenId: '30',
    price: '3400',
    toAddress: '0xb66456bf0cf94f7eceaeca7e5db1cd5b32f96344',
    fromAddress: '0xc94ebb328ac25b95db0e0aa968371885fa516215',
    txHash:
      '0x7a91b275e238657f24a288a3ad586643865d5a3db5f605947b3c3586ef5e8092',
    time: '1 day ago',
  },
  {
    name: 'Hero #30',
    image: '/hero4.png',
    tokenId: '30',
    price: '3400',
    toAddress: '0xb66456bf0cf94f7eceaeca7e5db1cd5b32f96344',
    fromAddress: '0xc94ebb328ac25b95db0e0aa968371885fa516215',
    txHash:
      '0x7a91b275e238657f24a288a3ad586643865d5a3db5f605947b3c3586ef5e8092',
    time: '1 day ago',
  },
  {
    name: 'Hero #30',
    image: '/hero4.png',
    tokenId: '30',
    price: '3400',
    toAddress: '0xb66456bf0cf94f7eceaeca7e5db1cd5b32f96344',
    fromAddress: '0xc94ebb328ac25b95db0e0aa968371885fa516215',
    txHash:
      '0x7a91b275e238657f24a288a3ad586643865d5a3db5f605947b3c3586ef5e8092',
    time: '1 day ago',
  },
];

export const stakeInfo: StakeInfo = {
  walletBalance: '1230',
  ethWalletBalance: '123',
  stakedBalance: '500',
  unclaimedRewards: '122',
  totalStakedBalance: '1930231',
  ethStakedBalance: '50',
  ethUnclaimedRewards: '12.2',
  ethTotalStakedBalance: '193023',
  apy: '7720%',
};
