import { api } from '.';
import { web3Util } from '../utils/web3Util';

export default async function buy(
  collection: string,
  tokenId: string | number,
) {
  const { args } = await api('/api/v1/buy', { collection, tokenId });
  if (args) {
    const contract = web3Util.getContract('market');
    return new Promise((resolve, reject) => {
      contract.methods
        .buy(...args)
        .send({ from: web3Util.accounts?.[0] })
        .on('transactionHash', (hash: string) => {
          resolve(hash);
        })
        .on('error', (e: unknown) => reject(e));
    });
  }
}
