import React, { useState, useContext, useRef } from 'react';
import styled from 'styled-components';
import claimAirdrop from '../../api/claimAirdrop';
import {
  Button,
  ExternalLink,
  Heading,
  Input,
  Layout,
  Link,
  P,
  Price,
  Row,
} from '../../components';
import { A } from '../../components/A';
import ConnectWallet from '../../components/ConnectWallet';
import { RoutingContext } from '../../contexts';
import useStore from '../../dataStore/Store';
import BN from 'bignumber.js';
import { web3Util } from '../../utils/web3Util';
import { EXCHANGE_LINK } from '../../config';
import approveMarketplaceSpend from '../../api/approveMarketplaceSpend';
import mintDungeon from '../../api/mintDungeon';
import useAudioHook from '../../components/useAudio';
import { Grid } from '@mui/material';
import { FrameContainer, IFrame } from '../Home';

const Wrap = styled.div`
  max-width: 1200px;
  margin: auto;
`;

const Spacer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 50px;
`;

const SpacerAlt = styled(Spacer)`
  text-align: left;
  gap: 16px;

  justify-content: center;
  align-items: flex-start;
`;

const HeadingAlt = styled(Heading)`
  margin-top: 20px;
  width: 100%;
`;

const SmallHeading = styled(HeadingAlt)`
  font-size: 24px;
`;

const SubHeading = styled.h2`
  font-size: 26px;
  margin: 0;
  letter-spacing: 1px;
`;

const Vision = styled.div`
  display: flex;
  position: relative;
  z-index: 2;
  gap: 20px;

  & div {
    flex: 1;
  }
  padding: 100px 0;
  @media (max-width: 600px) {
    flex-direction: column;
    padding: 50px 18px;
  }
`;

const Img = styled.div`
  border-radius: 30px;
  overflow: hidden;
  & img {
    width: 100%;
  }
`;

const VideoWrap = styled.div`
  height: calc(100vh - 120px);
  min-height: 480px;
  background: black;
  position: relative;
  & video {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const MainContent = styled.div`
  opacity: 0;
  transition: opacity 1s;
  pointer-events: none;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props: { visible: boolean }) =>
    props.visible
      ? `
  pointer-events: all;
  opacity: 1;
  `
      : ``}
`;

const DungeonsHeader = styled(Heading)`
  font-size: 72px;
  color: red;
  display: flex;
  align-items: baseline;
  & span {
    margin: 0 10px 0 15px;
  }
`;

const Skip = styled.div`
  position: absolute;
  top: 15px;
  left: 15px;
  color: white;
  cursor: pointer;
  padding: 10px 25px;
  background: black;
`;

const BackgroundRow = styled.div`
  position: relative;
  display: flex;
  height: 500px;
  max-height: 100vh;
  padding: 100px 0;
  min-height: 265px;
  overflow: hidden;

  @media (max-width: 600px) {
    padding: 50px 18px;
  }
`;

const BackgroundRowAlt = styled(BackgroundRow)`
  height: auto;
  max-height: none;
`;

const BgImg = styled.img`
  position: absolute;
  width: 200%;
  image-rendering: pixelated;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  z-index: 1;
  min-height: 100%;
`;

const BackgroundRowInner = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  gap: 10px;
`;

const Gradient = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.2) 90%);
  position: absolute;
  width: 100%;
  z-index: 1;
  height: 100%;
  left: 0;
  top: 0;
`;

const approvalAmount = new BN(9999999999).times(10 ** 18).toFixed();

export function DungeonsPage() {
  const { viewerAddress, getBalance } = useStore(s => ({
    viewerAddress: s.viewerAddress,
    getBalance: s.getBalance,
  }));
  const { playing, play, pause } = useAudioHook('/war-shout.mp3', true);

  const [claim, updateClaim] = useState<null | {
    proof: string[];
    leaf: string;
    amount: string;
  }>();
  const [hasClaimed, updateHasClaimed] = useState(false);
  const [loading, updateLoading] = useState(true);
  const [showContent, updateShowContent] = useState(false);
  const [pubMintActive, updatePubMintActive] = useState(false);
  const [allowlistMintActive, updateAllowlistActive] = useState(false);
  const [approveRequired, updateApproveRequired] = useState(false);
  const [amountToMint, updateAmountToMint] = useState('1');
  const [dungeonBalance, updateBalance] = useState('...');
  const [totalAllowlistMinted, updateTotalAllowlistMinted] = useState('...');
  const { route } = useContext(RoutingContext);
  const showDate =
    !viewerAddress || (!loading && !pubMintActive && !allowlistMintActive);

  React.useEffect(() => {
    if (viewerAddress) {
      const fn = async () => {
        // set loading
        const contract = web3Util.getContract('dungeons');
        const b = await contract.methods.totalSupply().call();
        updateBalance(new BN(3333).minus(b.toString()).toString());
        updatePubMintActive(true);
        updateLoading(false);
      };
      fn();
    }
  }, [viewerAddress]);

  const videoRef = useRef<HTMLVideoElement>(null);

  const showMainContent = () => {
    updateShowContent(true);
  };

  React.useLayoutEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener('ended', () => {
        showMainContent();
      });
    }
  }, []);

  const replayVideo = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      updateShowContent(false);
      videoRef.current.play();
    }
  };

  const approveHearts = () => {
    approveMarketplaceSpend(
      approvalAmount,
      process.env.REACT_APP_DUNGEONS,
    ).then(tx => {
      if (tx !== null) route(`/transaction/${tx}?next=/dungeons#mint`);
    });
  };

  const [error, updateError] = useState('');

  const onMint = async () => {
    try {
      if (error) {
        updateError('');
      }
      const amount = new BN(amountToMint);

      if (amount.isNaN()) {
        updateError('Please enter a valid number');
        return;
      }
      if (amount.lte(0)) {
        updateError('Please enter a number greater than 0');
        return;
      }

      if (amount.gt(20)) {
        updateError('You can mint up to 20 dungeons per transaction.');
        return;
      }
      await mintDungeon(amount.toFixed(0));
    } catch (e: any) {
      updateError(e.message);
    }
  };

  let maxMintText;

  if (allowlistMintActive) {
    maxMintText = 'Max 3 during allowlist mint.';
  }
  if (pubMintActive) {
    maxMintText = 'Max 20 per transaction.';
  }

  return (
    <Layout fullWidth>
      <VideoWrap>
        {!showContent && (
          <video ref={videoRef} autoPlay muted src="/cut-scene.mp4" />
        )}
        <MainContent visible={showContent}>
          <div>
            <DungeonsHeader>
              <img src="/torch.gif" />
              <span style={{ textAlign: 'center' }}>Merlin's Dungeons</span>
              <img src="/torch.gif" />
            </DungeonsHeader>
            <HeadingAlt style={{ textAlign: 'center' }}>
              A Heroes NFT Collection
            </HeadingAlt>
            <A style={{ textDecoration: 'underline' }} href="#mint">
              <HeadingAlt style={{ textAlign: 'center' }}>Mint</HeadingAlt>
            </A>
            <A
              style={{ textDecoration: 'underline' }}
              href=""
              onClick={replayVideo}
            >
              <HeadingAlt style={{ textAlign: 'center', fontSize: '18px' }}>
                Replay video
              </HeadingAlt>
            </A>
          </div>
        </MainContent>
        {!showContent && <Skip onClick={showMainContent}>Skip</Skip>}
        {
          <Skip
            style={{ top: '60px' }}
            onClick={() => {
              if (playing) {
                pause();
              } else {
                play();
              }
            }}
          >
            {playing ? 'No music' : 'Music'}
          </Skip>
        }
      </VideoWrap>
      <BackgroundRow id="mint">
        <BackgroundRowInner>
          <ConnectWallet text="Connect wallet to mint">
            {!showDate && !loading && !approveRequired && (
              <>
                <p style={{ marginBottom: '10px' }}>
                  How many would you like to mint? {maxMintText}
                </p>
                <Row>
                  <div>
                    <Input
                      onChange={e => {
                        updateAmountToMint(e.target.value);
                      }}
                      value={amountToMint}
                    />
                  </div>
                  <Button
                    style={{
                      width: '100%',
                      maxWidth: '300px',

                      marginBottom: '10px',
                    }}
                    onClick={onMint}
                  >
                    Mint
                  </Button>
                </Row>
              </>
            )}
          </ConnectWallet>
          {error && (
            <p style={{ margin: '10px 0', color: 'red', background: 'black' }}>
              {error}
            </p>
          )}
          <Heading style={{ lineHeight: 0.8 }}>Cost: 0.019 ETH</Heading>

          {!loading && <SubHeading>{dungeonBalance} / 3333 Remain</SubHeading>}
        </BackgroundRowInner>
        <BgImg src="/embers-glow.png" />
        <Gradient />
      </BackgroundRow>
      <Wrap>
        <Vision>
          <SpacerAlt>
            <HeadingAlt>Play your Hero in the Dungeons!</HeadingAlt>
            <HeadingAlt>Fight Rats, Skeletons, and the Hordes!</HeadingAlt>
            <HeadingAlt>Get Gold, Experience, and Loot!</HeadingAlt>
            <HeadingAlt>Beat Merlin's Challenge…</HeadingAlt>
            <HeadingAlt>…and later…</HeadingAlt>
            <HeadingAlt>Save the kingdom!</HeadingAlt>
          </SpacerAlt>
          <div>
            <Img>
              <img src="/dungeon-gif.gif" alt="Dungeons" />
            </Img>
          </div>
        </Vision>
      </Wrap>
      <Wrap style={{ marginBottom: '5rem' }}>
        <Grid container spacing={'1rem'}>
          <Grid item xs={12} md={6}>
            <FrameContainer>
              <IFrame
                src="https://www.youtube.com/embed/bP_k4_6_koo"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></IFrame>
            </FrameContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <Heading>Dungeon Events and Staking</Heading>
            <P>
              Test yourself in Merlin's Dungeons! Stake your dungeon and it will
              become part of a pool of dungeons used for thrilling adventures.
              Dungeons will be selected from the pool and chained together for
              weekly events. Heroes will explore linked dungeons filled with
              enemies to slay, powerful bosses to defeat, and valuable treasures
              to earn. When your dungeon is selected for an event, you'll
              receive a share of the treasure found within it as players
              progress through the challenges. Immerse yourself in a world of
              excitement as you compete with other players to emerge as a top
              player and earn even greater rewards. Join the action and
              experience the thrill of Merlin's Dungeons!
            </P>
          </Grid>
        </Grid>
      </Wrap>
      <BackgroundRowAlt id="mint">
        <Wrap>
          <Vision>
            <SpacerAlt style={{ gap: '20px' }}>
              <HeadingAlt>
                The Kingdom needs you.
                <br />
                Master your skills in the Dungeons.
              </HeadingAlt>
              <P style={{ margin: 0 }}>
                Each Dungeon NFT is a programatically generated image created
                from the on-chain map data of the Crypts and Caverns NFTs. Using
                the map metadata stored on-chain we've written code to create
                re-skinned tile sets in the Heroes universe. Using the “biomes”
                of each dungeon as “environments” in the Heroes universe and
                honoring the “doors” and “points of interest” markers,
                re-interpreted for the Heroes universe.
              </P>
              <P style={{ margin: 0 }}>
                Dungeons will be the first playable experience leading into the
                larger Heroes Kingdom. In the first instance Heroes will fight
                waves of skeletons, rats, and hordes in the dungeons to earn
                experience points and gold. You will also be able to earn
                resources which help you craft items later on. The first
                iteration of crafting will include items from the Loot NFT
                collection.
              </P>
            </SpacerAlt>
            <div>
              <Img>
                <img src="/skeleton-fight.gif" alt="Skeleton fight" />
              </Img>
            </div>
          </Vision>
        </Wrap>
        <BgImg src="/forest-ruins.png" />
        <Gradient />
      </BackgroundRowAlt>
      <Wrap style={{ maxWidth: '600px' }}>
        {/* <Vision>
          <SpacerAlt>
            <HeadingAlt style={{ textAlign: 'center' }}>FAQ</HeadingAlt>
            <SmallHeading>When will Dungeons be playable?</SmallHeading>
            <p>
              We are currently finalizing our game studio selection via RFP
              process. We are targeting a 6 month development cycle.
            </p>
            <SmallHeading>
              What is the feature list for the intial versions of the playable
              Dungeons?
            </SmallHeading>
            <p style={{ marginBottom: '10px' }}>
              The initial proposed feature list for Dungeons includes (subject
              to change):
            </p>
            <ul style={{ margin: '10px 0' }}>
              <li>
                Playable Hero characters for all Heroes in the Heroes NFT
                collection
              </li>
              <li>Playable Dungeon environments</li>
              <li>
                Combat against non-player enemies (rats, skeletons, hordes)
              </li>
              <li>Level ups and resource collection</li>
              <li>Crafting and trading</li>
            </ul>

            <SmallHeading>When is the allowlist mint?</SmallHeading>
            <p>
              A snapshot of Heroes and Crypts and Caverns holders was taken on
              4/25, those who continue to hold one Heroes or C&C NFT when
              minting will be eligible to mint beginning May 14th, 2022 at 11 AM
              ET.
            </p>
            <SmallHeading>How many dungeons can I mint?</SmallHeading>
            <p>
              3 max during allowlist mint. Up to 20 per transaction during
              public mint.
            </p>
            <SmallHeading>When is the public mint?</SmallHeading>
            <p>Public mint begins May 15th, 2022 at 11 AM ET.</p>
            <SmallHeading>Is there a team mint?</SmallHeading>
            <p>
              Yes the team will mint 100 dungeons before the allowlist mint.
            </p>
          </SpacerAlt>
        </Vision> */}
      </Wrap>
    </Layout>
  );
}
