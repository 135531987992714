import React, { FC, useState } from 'react';
import styled from 'styled-components';
import {
  Layout,
  Price,
  HandlerProps,
  LoadingIndicator,
  Heading,
  HR,
  Row,
  Col,
  SmOnly,
  LgOnly,
} from '../../components';
import { dataStore } from '../../dataStore';
import { useAsyncData } from '../../utils';
import { Stats } from './Stats';
import { Traits } from './Traits';
import { Actions } from './Actions';
import getToken from '../../api/getToken';
import BN from 'bignumber.js';
import useStore from '../../dataStore/Store';

const Image = styled.img`
  aspect-ratio: 1;
  width: 100%;
  border-radius: ${t => t.theme.borderRadius.m};
`;
const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${t => t.theme.space.xs};
  font-size: ${t => t.theme.fontSize.m};
`;

const ACTIVITY_RESULTS_PER_PAGE = 10;

export const TokenPage: FC<HandlerProps> = ({
  path,
  pathParams: { collection, tokenId },
}) => {
  const [nonce, updateNonce] = useState(0);
  const [, , tokenData] = useAsyncData(
    () => getToken({ tokenId }),
    [collection, tokenId, nonce],
  );
  const [, , activityData] = useAsyncData(() =>
    dataStore.activity({
      collection,
      tokenId,
      page: 1,
      resultsPerPage: ACTIVITY_RESULTS_PER_PAGE,
    }),
  );
  const store = useStore();

  if (tokenData === null) {
    return (
      <Layout>
        <LoadingIndicator />
      </Layout>
    );
  }

  const listed = tokenData.price !== undefined;
  const viewerIsOwner =
    tokenData.owner.toLowerCase() === store.viewerAddress?.toLowerCase();

  const info = (
    <Info>
      <Heading>{tokenData.name}</Heading>
    </Info>
  );

  const actions = (
    <Actions
      listed={listed}
      viewerIsOwner={viewerIsOwner}
      tokenData={tokenData}
      path={path}
      refresh={() => updateNonce(n => n + 1)}
    />
  );

  return (
    <Layout>
      <Row gap="l" responsive align="stretch">
        <Col style={{ flexShrink: 0, flex: 1 }} align="stretch" gap="l">
          <SmOnly>{info}</SmOnly>
          <Image
            src={tokenData.image.replace(
              'ipfs.infura.io',
              'heroes.infura-ipfs.io',
            )}
          />
          <SmOnly>{actions}</SmOnly>
          <HR />
          <Stats stats={tokenData.stats} />
        </Col>
        <Col align="stretch" style={{ flex: 1 }} gap="l">
          <LgOnly>{info}</LgOnly>
          <LgOnly>{actions}</LgOnly>
          <HR />
          <Traits traits={tokenData.traits} />
          {activityData && (
            <>
              {/* <HR />
                <Col gap="s" align="stretch">
                  <div>Activity</div>
                  <ActivityTable
                    collection={collection}
                    activity={activityData.results}
                    showItem={false}
                  />
                  {activityData.totalResults > 10 && (
                    <Link
                      href={`/collection/${collection}/${tokenId}/activity`}
                    >
                      More
                    </Link>
                  )}
                </Col> */}
            </>
          )}
        </Col>
      </Row>
    </Layout>
  );
};
