import styled from 'styled-components';

export const Input = styled.input`
  border: solid 1px ${t => t.theme.color.border.a};
  color: ${t => t.theme.color.border.a};
  background: #fff;
  width: 12em;
  height: 3em;
  padding: 0 1rem;

  border-radius: ${t => t.theme.borderRadius.s};
  font-family: ${t => t.theme.font.body};
  font-size: ${t => t.theme.fontSize.s};
`;
