import React, { FC } from 'react';
import {
  HandlerProps,
  Layout,
  LoadingIndicator,
  PaginationControls,
  Heading,
  Col,
  TokenGrid,
} from '../../components';
import { dataStore } from '../../dataStore';
import { parsePage, shortAddress, useAsyncData } from '../../utils';
import { WalletTabs } from './WalletTabs';
import getListings from '../../api/getListings';
import { ROOT_COLLECTION } from '../../config';
import getInventory from '../../api/getInventory';

const RESULTS_PER_PAGE = 12;

export const WalletPage: FC<HandlerProps> = ({
  path,
  pathParams: { address },
  searchParams: { page: pageString },
}) => {
  const page = parsePage(pageString);
  const type = path.match(/listings$/) ? 'listings' : 'inventory';
  const [, , data] = useAsyncData(
    () =>
      // @ts-ignore
      type === 'inventory'
        ? getInventory({
            address,
            page,
            resultsPerPage: RESULTS_PER_PAGE,
          })
        : getListings({ address, collection: ROOT_COLLECTION }),
    [page],
  );

  const viewerIsOwner = address === dataStore.viewer();

  return (
    <Layout>
      <Col align="stretch" gap="l">
        <Heading>
          {viewerIsOwner ? 'Your Heroes' : shortAddress(address)}
        </Heading>
        <WalletTabs address={address} active={type} />
        {data?.results ? (
          <>
            {data.results.length > 0 && (
              <>
                <TokenGrid tokens={data?.results} />
                <PaginationControls
                  page={page}
                  resultsPerPage={RESULTS_PER_PAGE}
                  // @ts-ignore
                  totalResults={parseInt(data.balance || data.totalResults)}
                  basePath={path}
                />
              </>
            )}
            {data.results.length === 0 && <>No results</>}
          </>
        ) : (
          <LoadingIndicator />
        )}
      </Col>
    </Layout>
  );
};
