import React, { FC } from 'react';
import styled from 'styled-components';
import { TextButton } from '../../components';
import { CategoryResponse } from '../../types';
import { StatControl } from './StatControl';
import { TraitControl } from './TraitControl';

const Categories = styled.div`
  border-top: solid 1px ${t => t.theme.color.border.a};
`;
const ClearFiltersButton = styled(TextButton)`
  margin: ${t => t.theme.space.l};
`;

export const BorderedCategory = styled(TraitControl)`
  border-bottom: solid 1px ${t => t.theme.color.border.a};
`;
export const BorderedStat = styled(StatControl)`
  border-bottom: solid 1px ${t => t.theme.color.border.a};
`;

export type FiltersProps = {
  categories: CategoryResponse;
  traits: Set<string>;
  stats: Map<string, [number, number]>;
  updateTrait: (id: string, on: boolean) => void;
  updateStat: (id: string, stat: [number, number] | null) => void;
  clearFilters: () => void;
};

export const Filters: FC<FiltersProps> = ({
  categories,
  traits,
  stats,
  updateTrait,
  updateStat,
  clearFilters,
}) => {
  return (
    <>
      <Categories>
        {categories.traits.map(category => (
          <BorderedCategory
            key={category.name}
            category={category}
            traits={traits}
            updateTrait={updateTrait}
          />
        ))}
        {categories.stats.map(category => (
          <BorderedStat
            key={category.name}
            category={category}
            stats={stats}
            updateStat={updateStat}
          />
        ))}
      </Categories>
      <ClearFiltersButton onClick={clearFilters} disabled={traits.size === 0}>
        Clear all filters
      </ClearFiltersButton>
    </>
  );
};
