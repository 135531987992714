import React, { FC, useContext, useState } from 'react';
import { TokenDetail } from '../../types';
import useStore from '../../dataStore/Store';
import { Col, Button, LinkedInput } from '../../components';
import { RoutingContext } from '../../contexts';
import listToken from '../../api/listToken';
import BN from 'bignumber.js';
import buy from '../../api/buy';
import approveMarketplaceSpend from '../../api/approveMarketplaceSpend';
import delistToken from '../../api/delistToken';
import { SetActiveHeroButton } from '../../components/SetActiveHeroButton';

export type ActionsProps = {
  listed: boolean;
  viewerIsOwner: boolean;
  tokenData: TokenDetail;
  path: string;
  refresh: () => void;
};

export const Actions: FC<ActionsProps> = ({
  listed,
  viewerIsOwner,
  tokenData,
  path,
  refresh,
}) => {
  const { route } = useContext(RoutingContext);
  const [value, setValue] = useState('');
  const store = useStore();
  const isApproved =
    store.marketApprovedAmount &&
    tokenData.price &&
    new BN(store.marketApprovedAmount).gt(tokenData.price);

  const isActiveHero = store.activeHero === tokenData.tokenId;

  // TODO: Check hearts approval before showing buy button.
  // user needs to approve hearts prior to marketplace buy

  // TODO: Verify wallet is connected prior to buying

  return (
    <Col gap="s" align="stretch">
      {viewerIsOwner && !isActiveHero && (
        <SetActiveHeroButton
          style={{ textAlign: 'left' }}
          tokenId={tokenData.tokenId}
        />
      )}
      {isActiveHero && `✅ Your Active Hero`}
    </Col>
  );
};
