import styled from 'styled-components';

export const TextButton = styled.button`
  font-size: 1rem;
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  display: inline-block;
  color: ${t => t.theme.color.text.link};
  font-family: ${t => t.theme.font.body};
  &:hover {
    color: ${t => t.theme.color.text.linkHover};
  }
  &:disabled {
    color: ${t => t.theme.color.text.disabled};
  }
`;
