import { api } from '.';

const getListingsMetadata = async () => {
  return api<{ price: string; totalListings: string; ethPrice: string }>(
    '/api/v1/listings-metadata',
    void 0,
    { method: 'GET' },
  );
};

export default getListingsMetadata;
