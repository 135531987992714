import React, { FC } from 'react';
import styled from 'styled-components';
import { TraitValue } from '../../types';

const Main = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: ${t => t.theme.space.s};
`;
const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${t => t.theme.space.s};
  padding: ${t => t.theme.space.s};
  border-radius: ${t => t.theme.borderRadius.m};
  border: solid 1px ${t => t.theme.color.border.a};
  background: ${t => t.theme.color.bg.b};
  text-align: center;
  font-size: ${t => t.theme.fontSize.xs}; ;
`;
const Name = styled.div`
  font-size: ${t => t.theme.fontSize.s};
`;

export type TraitsProps = {
  traits: TraitValue[];
};

export const Traits: FC<TraitsProps> = ({ traits }) => {
  return (
    <Main>
      {traits.map(({ type, value }) => (
        <Card key={type}>
          <div>{type}</div>
          <Name>{value}</Name>
          {/* <div>{percent} have this trait</div> */}
        </Card>
      ))}
    </Main>
  );
};
