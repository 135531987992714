import React, { FC } from 'react';
import styled from 'styled-components';
import { Layout, Link } from '../../components';

const H1 = styled.h1`
  font-family: ${t => t.theme.font.display};
  font-size: ${t => t.theme.fontSize.xl};
`;

export const NotFoundPage: FC = () => {
  return (
    <Layout>
      <H1>404</H1>
      <Link href="/">home</Link>
    </Layout>
  );
};
