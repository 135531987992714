export function api<T>(path: string, body: any, override?: any): Promise<T> {
  return fetch(
    `${process.env.REACT_APP_REQUEST_URL}${path}`,
    override
      ? override
      : {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: body ? JSON.stringify(body) : void 0,
        },
  ).then(response => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  });
}
