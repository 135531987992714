import { web3Util } from '../utils/web3Util';
import BN from 'bignumber.js';
import { StakeInfo } from '../types';

const tryWithFallback = async <T>(
  fn: () => Promise<T>,
  fallback: T,
): Promise<T> => {
  try {
    const result = await fn();
    return result;
  } catch (e) {
    return fallback;
  }
};

const numFrom = (val: string) => new BN(val).div(10 ** 18).toFixed(4, 1);

export default async function staking(walletAddress: string) {
  await web3Util.enable();
  const heartsContract = web3Util.getContract('hearts');
  const lpContract = web3Util.getContract('lp');
  const heartStaking = web3Util.getContract('heartStaking');
  const lpStaking = web3Util.getContract('lpStaking');
  const [
    stakingContractBalance,
    totalHeartsStaked,
    walletHeartsBalance,
    stakedHeartsBalance,
    unclaimedHeartsRewards,
    totalLPStaked,
    walletLPBalance,
    stakedLPBalance,
    unclaimedLPRewards,
    totalLPSupply,
    totalHeartSupply,
    totalLpRewardsOwed,
    totalHeartRewardsOwed,
  ] = await Promise.all([
    // staking contract balance
    (async () => {
      const [b1, b2] = await Promise.all([
        heartsContract.methods
          .balanceOf(process.env.REACT_APP_HEART_STAKING)
          .call(),
        heartsContract.methods
          .balanceOf(process.env.REACT_APP_LP_STAKING)
          .call(),
      ]);

      return new BN(b1.toString()).plus(b2).toFixed();
    })(),
    // totalHeartsStaked
    heartStaking.methods.totalStaked().call(),
    //walletHeartsBalance
    heartsContract.methods.balanceOf(walletAddress).call(),
    // stakedHeartsBalance
    tryWithFallback(
      () =>
        heartStaking.methods
          .stakes(walletAddress)
          .call()
          .then((stake: any) => stake.totalStaked),
      '0',
    ),
    //unclaimedHeartsRewards
    tryWithFallback(
      () => heartStaking.methods.calcRewards(walletAddress).call(),
      '0',
    ),
    // totalLPStaked
    lpStaking.methods.totalStaked().call(),
    //walletLPBalance
    lpContract.methods.balanceOf(walletAddress).call(),
    //stakedLPBalance
    tryWithFallback(async () => {
      const stake = await lpStaking.methods.stakes(walletAddress).call();
      return stake.totalStaked;
    }, '0'),

    // unclaimedLPRewards
    tryWithFallback(
      () => lpStaking.methods.calcRewards(walletAddress).call(),
      '0',
    ),
    lpContract.methods.totalSupply().call(),
    // get total supply of hearts in sushi
    heartsContract.methods
      .balanceOf('0x404e716257C4f4BCC43B967514520b98c0C09dd5')
      .call(),

    lpStaking.methods.totalRewards().call(),
    heartStaking.methods.totalRewards().call(),
  ]);

  const rewardPoolBalanceBn = new BN(stakingContractBalance)
    .minus(totalHeartsStaked)
    .minus(totalLpRewardsOwed)
    .minus(totalHeartRewardsOwed);

  const priceOfLpInHearts = new BN(totalHeartSupply).div(totalLPSupply);

  const rewardPoolBalance = rewardPoolBalanceBn.toFixed();

  // TODO: mocked with fixed conversion rate for now, in future load conversion
  // rate from server
  const eth = (n: string) => new BN(n).times(0.01).toString();
  const rewardPerTokenPerDay = (totalStaked: string, conversion: BN) =>
    `${rewardPoolBalanceBn
      .div(2)
      .times(365)
      .div(conversion)
      .div(new BN(totalStaked).plus(1))
      .toFixed(2)}`;

  const aprToApy = (num: string) => {
    return ((1 + parseFloat(num) / 100 / 365) ** 365 - 1) * 100;
  };

  return {
    rewardPoolBalance: numFrom(rewardPoolBalance),
    ethRewardPoolBalance: eth(rewardPoolBalance),
    token: {
      walletBalance: numFrom(walletHeartsBalance),
      stakedBalance: numFrom(stakedHeartsBalance),
      unclaimedRewards: numFrom(unclaimedHeartsRewards),
      totalStakedBalance: numFrom(totalHeartsStaked),
      ethWalletBalance: eth(walletHeartsBalance),
      ethStakedBalance: eth(stakedHeartsBalance),
      ethUnclaimedRewards: eth(unclaimedHeartsRewards),
      ethTotalStakedBalance: eth(totalHeartsStaked),
      apy: aprToApy(
        rewardPerTokenPerDay(totalHeartsStaked, new BN(1)),
      ).toString(),
    } as StakeInfo,
    lpToken: {
      walletBalance: numFrom(walletLPBalance),
      stakedBalance: numFrom(stakedLPBalance),
      unclaimedRewards: numFrom(unclaimedLPRewards),
      totalStakedBalance: numFrom(totalLPStaked),
      ethWalletBalance: eth(walletLPBalance),
      ethStakedBalance: eth(stakedLPBalance),
      ethUnclaimedRewards: eth(unclaimedLPRewards),
      ethTotalStakedBalance: eth(totalLPStaked),
      apy: aprToApy(
        rewardPerTokenPerDay(totalLPStaked, priceOfLpInHearts),
      ).toString(),
    } as StakeInfo,
  };
}
