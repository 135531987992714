import React, { FC } from 'react';
import { Row } from './Row';
import { Link } from './Link';

export type PaginationControlsProps = {
  page: number;
  resultsPerPage: number;
  totalResults: number;
  basePath: string;
  className?: string;
};

export const PaginationControls: FC<PaginationControlsProps> = ({
  page,
  resultsPerPage,
  totalResults,
  basePath,
  className,
}) => {
  const numPages = Math.ceil(totalResults / resultsPerPage);
  const path: (page: number) => string = basePath.includes('?')
    ? page => `${basePath}&page=${page}`
    : page => `${basePath}?page=${page}`;

  return (
    <Row justify="space-between" padding="0 s" className={className}>
      <Link href={path(page - 1)} disabled={page <= 1}>
        previous
      </Link>
      <span>
        page {page} of {Number.isNaN(numPages) ? 0 : numPages}
      </span>
      <Link href={path(page + 1)} disabled={page >= numPages}>
        next
      </Link>
    </Row>
  );
};
