import { api } from '.';
import { GetInventoryResponse, InventoryRequest } from '../types';

const getInventory = async (body: InventoryRequest) => {
  const { results, balance } = await api<GetInventoryResponse>(
    '/api/v1/inventory',
    body,
  );
  return { results, balance };
};

export default getInventory;
