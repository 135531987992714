export const repeat = <Return>(
  times: number,
  fn: (index: number) => Return,
): Return[] => {
  const result = new Array(times);
  for (let i = 0; i < times; i++) {
    result[i] = fn(i);
  }
  return result;
};
