import { web3Util } from '../utils/web3Util';
import { tx } from './tx';

export async function stakeHearts(amount: string) {
  const stakingContract = web3Util.getContract('heartStaking');
  return tx(stakingContract.methods.stake(amount));
}

export async function stakeLP(amount: string) {
  const stakingContract = web3Util.getContract('lpStaking');
  return tx(stakingContract.methods.stake(amount));
}
