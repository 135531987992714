import React, { FC, useContext, useMemo } from 'react';
import styled from 'styled-components';
import { Price, Link, LinkProps, TextButton, Row, Col } from '.';
import { RoutingContext } from '../contexts';
import { Token } from '../types';
import { shortAddress } from '../utils';
import useStore from '../dataStore/Store';
import BN from 'bignumber.js';
import buy from '../api/buy';
import { SetActiveHeroButton } from './SetActiveHeroButton';

type CardProps = LinkProps & {
  active?: boolean;
};

const Card = styled(Link)<CardProps>`
  border-radius: ${t => t.theme.borderRadius.m};
  background: ${t => t.theme.color.bg.a};
  text-decoration: none;
  overflow: hidden;
  color: ${t => t.theme.color.text.body};
  font-size: ${t => t.theme.fontSize.s};
  border: solid 1px ${t => t.theme.color.border.a};
  ${props =>
    props.active ? `box-shadow: 0 0 20px ${props.theme.color.text.link}` : ''}
`;
const Image = styled.img`
  aspect-ratio: 1;
  width: 100%;
`;
const Unlisted = styled.span`
  color: ${t => t.theme.color.text.disabled};
`;

const Spacer = styled.div`
  & > * {
    margin-bottom: 10px;
  }
`;

export type TokenCardProps = {
  token: Token;
};

export const TokenCard: FC<TokenCardProps> = ({
  token: { name, tokenId, collection, image, price, owner },
}) => {
  const { route } = useContext(RoutingContext);
  const store = useStore();
  const listed = price !== undefined;
  const viewerIsOwner = owner === store.viewerAddress;
  price = useMemo(() => new BN(price ?? '0').div(10 ** 18).toString(), [price]);
  const isApproved =
    store.marketApprovedAmount &&
    price &&
    new BN(store.marketApprovedAmount).gt(price);
  const activeHero = store.activeHero === tokenId;

  console.log(store.activeHero, tokenId);

  return (
    <Card active={activeHero} href={`/collection/${collection}/${tokenId}`}>
      <Image src={image.replace('ipfs.infura.io', 'heroes.mypinata.cloud')} />
      <Col padding="s" gap="m" align="stretch">
        <Row justify="space-between">
          {/* {listed ? <Price price={price} /> : <Unlisted>Unlisted</Unlisted>} */}
          <span>{name}</span>
        </Row>
        <Row justify="space-between">
          {viewerIsOwner ? (
            <Spacer>
              <div>Owned by you</div>
              {!activeHero && <SetActiveHeroButton tokenId={tokenId} />}
              {activeHero && `✅ Active Hero`}
            </Spacer>
          ) : !isApproved ? (
            <TextButton tabIndex={-1}>View</TextButton>
          ) : listed ? (
            <TextButton
              tabIndex={-1}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                buy(collection, tokenId).then(tx => {
                  if (tx !== null)
                    route(
                      `/transaction/${tx}?next=/collection/${collection}/${tokenId}`,
                    );
                });
              }}
            >
              Buy now
            </TextButton>
          ) : (
            <Unlisted>Owned by {shortAddress(owner)}</Unlisted>
          )}
        </Row>
      </Col>
    </Card>
  );
};
