import styled from 'styled-components';

export const Button = styled.button<{ fullWidth?: boolean }>`
  border: solid 1px ${t => t.theme.color.border.a};
  color: ${t => t.theme.color.text.link};
  background: ${t => t.theme.color.bg.a};
  border-radius: ${t => t.theme.borderRadius.s};
  font-family: ${t => t.theme.font.body};
  display: ${t => (t.fullWidth ? 'block' : 'inline-block')};
  height: 3rem;
  width: auto;
  padding: 0 1.5rem;
  cursor: pointer;
  &:disabled {
    background: none;
    color: ${t => t.theme.color.text.deemphesized};

    &:hover {
      color: ${t => t.theme.color.text.deemphesized};
    }
  }
  &:hover {
    color: ${t => t.theme.color.text.linkHover};
  }
`;
