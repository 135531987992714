import { web3Util } from '../utils/web3Util';
import { tx } from './tx';

export async function claimHearts(): Promise<string> {
  const stakingContract = web3Util.getContract('heartStaking');
  return tx(stakingContract.methods.claimAndStakeRewards());
}

export async function claimLP(): Promise<string> {
  const stakingContract = web3Util.getContract('lpStaking');
  return tx(stakingContract.methods.claimAndWithdrawRewards());
}
